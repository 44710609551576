import { Grid, TextField, useMediaQuery } from '@mui/material';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { StyledErrorMessage } from '../StyledErrorMessage';
import { theme, useLanguage } from '../../common/GeneralUtils';
import { useAuth } from '../../session/InternalAuthProvider';
import { AccountData } from '../../api';

export const FormLabelField = ({
  labelFieldName,
  label,
  disabled,
}: {
  labelFieldName: string;
  label: string;
  disabled: boolean;
}) => {
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const languages = account.customer_facing_widgets_languages;
  const language = useLanguage();
  const enLabel = language === 'en' ? `English ${label}` : `${label} באנגלית`;
  const heLabel = language === 'en' ? `Hebrew ${label}` : `${label} בעברית`;

  return (
    <Grid
      container
      gap={2}
      border={isSmallScreen ? '1px solid #bfbfbf' : undefined}
      borderRadius={isSmallScreen ? '4px' : undefined}
      p={isSmallScreen ? 1 : undefined}
      flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
      justifyContent={'flex-start'}>
      {languages.map((x) => {
        return (
          <Grid item xs={!isSmallScreen ? 4 : 12}>
            <Field name={`${labelFieldName}.${x}`}>
              {({ field }: FieldProps<string>) => (
                <TextField
                  size="small"
                  {...field}
                  value={field.value}
                  label={x === 'en' ? enLabel : heLabel}
                  variant="outlined"
                  fullWidth
                  multiline
                  disabled={disabled}
                />
              )}
            </Field>
            <StyledErrorMessage name={`${labelFieldName}.en`} />
          </Grid>
        );
      })}
    </Grid>
  );
};
