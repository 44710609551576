import {
  CustomDetailData,
  CustomPersonalDetailData,
  EnrichedEventData,
  OrderData,
  WaitingListItemData,
} from '../../../../api';

enum FieldType {
  FIELD = 'field',
  CUSTOM_PERSONAL_DETAIL = 'customPersonalDetail',
  CUSTOM_DETAIL = 'customDetail',
}

const extractCustomPersonalDetailsValue = (order: OrderData, key?: string): string => {
  if (!key) {
    return '';
  }
  return order.custom_personal_details?.[key] ?? '';
};
const extractCustomDetailsValue = (order: OrderData, key?: string): string => {
  if (!key) {
    return '';
  }
  return order.custom_details?.[key] ?? '';
};

const shouldHideHeader = (orderPageCustomPersonalDetailData: CustomPersonalDetailData[], customDetailId: string) => {
  const customDetail = orderPageCustomPersonalDetailData.find((x) => x.id === customDetailId);
  return customDetail?.hide_from_console;
};

const extractHeaderNameFromCustomPersonalDetail = (
  orderPageCustomPersonalDetailData: CustomPersonalDetailData[],
  customDetailId: string,
) => {
  const customDetail = orderPageCustomPersonalDetailData.find((x) => x.id === customDetailId);
  const label = customDetail?.console_label || customDetail?.label;
  return label ? label.en : undefined;
};

const extractHeaderNameFromCustomDetail = (orderPageCustomDetails: CustomDetailData[], customDetailId: string) => {
  const customDetail = orderPageCustomDetails.find((x) => x.name === customDetailId);
  const label = customDetail?.label;
  return label ? label.en : undefined;
};

const convertOrdersToCSV = (
  data: OrderData[],
  selectedFields: (keyof OrderData)[],
  orderPageCustomPersonalDetailData: CustomPersonalDetailData[],
  orderPageCustomDetails: CustomDetailData[],
): string => {
  const extractCustomPersonalDetailsKeys = (order: OrderData): string[] => {
    const customPersonalDetails = order.custom_personal_details;
    return customPersonalDetails ? Object.keys(customPersonalDetails) : [];
  };
  const extractCustomDetailsKeys = (order: OrderData): string[] => {
    const customDetails = order.custom_details;
    return customDetails ? Object.keys(customDetails) : [];
  };

  const allSelectedFields: {
    headerName: string;
    headerId?: string;
    type: FieldType;
  }[] = [
    ...selectedFields
      .map((x) => ({ headerName: x, type: FieldType.FIELD }))
      .filter((x) => x.headerName !== 'custom_personal_details'),
    ...data
      .reduce<string[]>((keys, order) => {
        const customDetailsKeys = extractCustomPersonalDetailsKeys(order);
        return Array.from(new Set([...keys, ...customDetailsKeys]));
      }, [])
      .map((x) => ({
        headerId: x,
        type: FieldType.CUSTOM_PERSONAL_DETAIL,
        headerName: extractHeaderNameFromCustomPersonalDetail(orderPageCustomPersonalDetailData, x) || '',
      }))
      .filter((x) => !shouldHideHeader(orderPageCustomPersonalDetailData, x.headerId)),
    ...data
      .reduce<string[]>((keys, order) => {
        const customDetailsKeys = extractCustomDetailsKeys(order);
        return Array.from(new Set([...keys, ...customDetailsKeys]));
      }, [])
      .map((x) => ({
        headerId: x,
        type: FieldType.CUSTOM_DETAIL,
        headerName: extractHeaderNameFromCustomDetail(orderPageCustomDetails, x) || '',
      })),
  ];

  const header = allSelectedFields.map((x) => x.headerName.split('_').join(' ').toLowerCase()).join(',');
  const rows = data.map((order) =>
    allSelectedFields
      .map((field) => {
        if (field.type === FieldType.FIELD) {
          // @ts-ignore
          const value = order[field.headerName];
          if (typeof value === 'boolean') {
            return value ? 'V' : undefined;
          }
          if (value && typeof value === 'object') {
            return JSON.stringify(value);
          }
          return typeof value === 'string' ? `"${value}"` : value;
        }
        if (field.type === FieldType.CUSTOM_PERSONAL_DETAIL) {
          const value = extractCustomPersonalDetailsValue(order, field.headerId);
          if (typeof value === 'boolean') {
            return value ? 'V' : undefined;
          }
          return value;
        }
        if (field.type === FieldType.CUSTOM_DETAIL) {
          const value = extractCustomDetailsValue(order, field.headerId);
          if (typeof value === 'boolean') {
            return value ? 'V' : undefined;
          }
          return value;
        }
        return null;
      })
      .join(','),
  );
  return [header, ...rows].join('\n');
};

const downloadCSV = (csv: string, fileName: string) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const handleOrdersDownload = (
  event: EnrichedEventData,
  data: OrderData[],
  orderPageCustomPersonalDetailData: CustomPersonalDetailData[],
  orderPageCustomDetails: CustomDetailData[],
) => {
  const csv = convertOrdersToCSV(
    data,
    [
      'returning_customer',
      'firstname',
      'lastname',
      'custom_personal_details',
      'persons',
      'order_number',
      'email',
      'phone',
      'comment',
    ],
    orderPageCustomPersonalDetailData,
    orderPageCustomDetails,
  );
  downloadCSV(csv, `Orders-${event.date}.csv`);
};
const convertVouchersToCSV = (data: WaitingListItemData[], selectedFields: (keyof WaitingListItemData)[]): string => {
  const header = selectedFields.map((x) => x.split('_').join(' ').toLowerCase()).join(',');
  const rows = data.map((order) =>
    selectedFields
      .map((field) => {
        // @ts-ignore
        const value = order[field];
        if (typeof value === 'boolean') {
          return value ? 'V' : undefined;
        }
        if (value && typeof value === 'object') {
          return JSON.stringify(value);
        }
        return typeof value === 'string' ? `"${value}"` : value;
      })
      .join(','),
  );
  return [header, ...rows].join('\n');
};
export const handleVouchersDownload = (event: EnrichedEventData, data: WaitingListItemData[]) => {
  const csv = convertVouchersToCSV(data, ['firstname', 'lastname', 'persons', 'email', 'phone']);
  downloadCSV(csv, `WaitList-${event.date}.csv`);
};
