import moment from 'moment';
import { some, sumBy } from 'lodash';
import { DATE_FORMAT } from '../../../calendar/CalendarWrapper';
import { DateAvailabilityOut, TimeSlotResponseOut } from '../../../api';

export enum DayType {
  AVAILABLE_DAY = 'availableDay',
  NOT_AVAILABLE_DAY = 'NotAvailableDay', // currently don't have those in the condensed cal
  FULLY_BOOKED_DAY = 'fullyBookedDay',
  RUNNING_OUT_DAY = 'runningOutDay',
}

type resolveDayTypeProps = {
  date?: moment.Moment;
  availableDatesDict: { [date: string]: DateAvailabilityOut };
  minDate: moment.Moment;
};

export const resolveDayType = ({ date, availableDatesDict, minDate }: resolveDayTypeProps): DayType => {
  if (!date) {
    return DayType.NOT_AVAILABLE_DAY;
  }
  const dateFromAvailableDate = availableDatesDict[date.format(DATE_FORMAT)];
  const isBookable = moment(date).isSameOrAfter(minDate);
  if (!isBookable) {
    return DayType.NOT_AVAILABLE_DAY;
  }

  if (!dateFromAvailableDate) {
    return DayType.NOT_AVAILABLE_DAY;
  }

  if (dateFromAvailableDate.times.length === 0) {
    return DayType.NOT_AVAILABLE_DAY;
  }

  // when customer has waiting list on, we should enable the clicking
  if (dateFromAvailableDate.isDisabled) {
    return DayType.FULLY_BOOKED_DAY;
  }

  const totalTicketsForDay = sumBy(dateFromAvailableDate.times, (item) => item.totalTickets || 0);
  const totalTicketsLeftForDay = sumBy(dateFromAvailableDate.times, (item) => item.ticketsLeft || 0);

  const isRunningOutForEvent = (totalTicketsLeftForDay * 100) / totalTicketsForDay < 30 && totalTicketsForDay > 0;

  const hasAtLeastOneRunningBookedEvent = some(dateFromAvailableDate.times, (item) => {
    return ((item.ticketsLeft || 0) * 100) / (item.totalTickets || 0) < 3;
  });
  const disabledPrivateCount = dateFromAvailableDate.times.filter((availableTime) => availableTime.isDisabled).length;
  const isPrivateOrderPage = !dateFromAvailableDate.times.find(
    (item: TimeSlotResponseOut) => item.ticketsLeft !== null,
  );
  const isRunningOutForPrivate =
    (disabledPrivateCount * 100) / dateFromAvailableDate.times.length > 70 && isPrivateOrderPage;
  if (isRunningOutForEvent || hasAtLeastOneRunningBookedEvent || isRunningOutForPrivate) {
    return DayType.RUNNING_OUT_DAY;
  }
  return DayType.AVAILABLE_DAY;
};
