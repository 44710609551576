import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid, Slider, ThemeProvider, useTheme } from '@mui/material';
import React from 'react';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { omit } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { KeyedMutator } from 'swr/_internal';
import Typography from '@mui/material/Typography';
import merge from 'lodash/merge';
import { CacheProvider } from '@emotion/react';
import { AccountData, AccountService, OrderPageData, PartialLabelData, UIObjectTypes } from '../../../api';
import { OrderPageUpdate } from '../../OrderPageSettingsPage';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { FormTextField } from '../../formComponents/FormTextField';
import { cacheLtr, ltrTheme, useLanguage } from '../../../common/GeneralUtils';
import { FormLabelField } from '../../formComponents/FormLabelField';
import TTLLocalStorage from '../../../common/TTLLocalStorage';

type SettingsSectionProps = {
  orderPage: OrderPageData;
  orderPageInitialValues: OrderPageUpdate;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};

export type SliderFormValues = {
  id: string;
  type: UIObjectTypes;
  min_value: number;
  max_value: number;
  label: PartialLabelData;
};

export type SettingsFormValues = {
  slider: SliderFormValues;
};

export const WidgetSection = ({
  orderPage,
  orderPageInitialValues,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: SettingsSectionProps) => {
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();
  const language = useLanguage();
  const posthog = usePostHog();
  const theme = useTheme();

  const validationSchema = yup.object().shape({
    slider: yup.object().shape({
      min_value: yup.number().required(t('editOrderPage.yup.required')).min(0, t('editOrderPage.yup.hasToBePoitive')),
      max_value: yup
        .number()
        .required(t('editOrderPage.yup.required'))
        .min(0, t('editOrderPage.yup.hasToBePoitive'))
        // can be equal
        .min(yup.ref('min_value'), t('editOrderPage.yup.hasToBeMoreThanMin')),
    }),
  });

  const slider = orderPage.objects.find((x) => x.type === UIObjectTypes.SLIDER);
  const initialValues: SettingsFormValues = {
    slider: {
      // @ts-ignore
      min_value: slider?.min_value,
      // @ts-ignore
      max_value: slider?.max_value,
      // @ts-ignore
      label: slider?.label,
      id: slider?.id || '',
      type: UIObjectTypes.SLIDER,
    },
  };

  const handleSubmit = async (values: SettingsFormValues) => {
    try {
      posthog.capture('Editing settings', { section: 'widgetSection', settings: values });
      const updatedAccount = await AccountService.updateOrderPage(
        omit(
          {
            ...orderPageInitialValues,
            ...values,
            id: orderPage.id,
          },
          'availability_settings.restrictions',
          'objects',
        ),
      );
      await updateLocalAccount(updatedAccount);
      TTLLocalStorage.removeByPrefix('order-page-state-');
      setIsUpdatedSnackOpen(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, isSubmitting, dirty, handleSubmit, setFieldValue }) => {
        const formIsDisabled = readOnly || isSubmitting;
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container justifyContent="space-between" flexWrap="nowrap" alignItems="flex-start">
              <Grid container flexDirection="column" gap={1} maxWidth="900px">
                <CacheProvider value={cacheLtr}>
                  <ThemeProvider theme={merge(theme, ltrTheme)}>
                    <Typography>{t('editOrderPage.slider.sliderDescription')}</Typography>
                    <FormLabelField labelFieldName="slider.label" label={''} disabled={formIsDisabled} />
                    <Typography variant="caption">{t('editOrderPage.slider.sliderCaption')}</Typography>
                    <Slider value={2} min={1} max={7} disabled />
                    <Grid
                      mt={-2}
                      container
                      flexWrap="nowrap"
                      flexDirection={language === 'he' ? 'row-reverse' : 'row'}
                      justifyContent="space-between">
                      <Grid item alignContent="flex-start">
                        <Typography variant="caption">{t('editOrderPage.slider.minValue')}</Typography>
                        <FormTextField
                          isFieldChanged={values.slider.min_value !== initialValues.slider.min_value}
                          width={70}
                          small
                          fieldName="slider.min_value"
                          disabled={formIsDisabled}
                          type="number"
                        />
                      </Grid>
                      <Grid item alignContent="flex-end">
                        <Typography variant="caption">{t('editOrderPage.slider.maxValue')}</Typography>
                        <FormTextField
                          isFieldChanged={values.slider.max_value !== initialValues.slider.max_value}
                          width={70}
                          small
                          fieldName="slider.max_value"
                          disabled={formIsDisabled}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </CacheProvider>
              </Grid>
              <LoadingButton
                size="large"
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || formIsDisabled}>
                {t('editOrderPage.orderPageBufferSubmit')}
              </LoadingButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
