import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../../../common/ui/ConfimationDialog';
import { UnavailabilityService } from '../../../api';
import { DeletionMode } from '../UserAvailabilityDialog/UserAvailabilityDeletionConfirmation';

type UnvailabilityUpdateDialogConfirmationProps = {
  dialogIsOpen: boolean;
  setUpdateDialogIsOpen: (isOpen: boolean) => void;
  onSubmit: (deletionMode: DeletionMode) => void;
  recurrent_note_id?: string;
};
export const NoteUpdateDialogConfirmation = ({
  dialogIsOpen,
  recurrent_note_id,
  onSubmit,
  setUpdateDialogIsOpen,
}: UnvailabilityUpdateDialogConfirmationProps) => {
  const { t } = useTranslation();
  const [deletionOption, setDeletionOption] = useState<DeletionMode>(DeletionMode.SINGLE);
  const [recurrentCount, setRecurrentCount] = useState<number | null>(null);

  const getRecurrentUnavailabilityCount = async (recurrentId?: string) => {
    try {
      if (!recurrentId) {
        return;
      }
      const count = await UnavailabilityService.getRecurrentUnavailabilityCount(recurrentId);
      setRecurrentCount(count);
    } catch (error) {
      console.error('Failed to fetch recurrent note count', error);
    }
  };
  useEffect(() => {
    setDeletionOption(DeletionMode.SINGLE);
    void getRecurrentUnavailabilityCount(recurrent_note_id);
  }, [recurrent_note_id]);
  return (
    <ConfirmationDialog
      confirmButtonColor="primary"
      ctaText={t('calendar.unavailability_dialog.edit_cta')}
      title={t('calendar.note_dialog.edit_title_questions')}
      content={
        <Grid container flexDirection="column">
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup value={deletionOption} onChange={(e) => setDeletionOption(e.target.value as DeletionMode)}>
                <FormControlLabel
                  value={DeletionMode.SINGLE}
                  control={<Radio />}
                  label={t('calendar.note_dialog.single')}
                />
                <FormControlLabel
                  value={DeletionMode.FROM_NOW_ON}
                  control={<Radio />}
                  label={
                    <Grid container gap={1} alignItems="baseline">
                      {t('calendar.note_dialog.repeatingUpdate')}
                      {recurrentCount !== null && (
                        <Typography variant="caption" color="grey">
                          {t('calendar.note_dialog.recurrent_count', { count: recurrentCount })}
                        </Typography>
                      )}
                    </Grid>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      }
      isOpen={dialogIsOpen}
      onClose={(actionConfirmed) => {
        setUpdateDialogIsOpen(false);

        if (actionConfirmed) {
          void onSubmit(deletionOption);
        }
      }}
    />
  );
};
