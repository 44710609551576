import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../../../common/ui/ConfimationDialog';
import { NoteData, NoteService } from '../../../api';
import { DeletionMode } from '../UserAvailabilityDialog/UserAvailabilityDeletionConfirmation';

type NoteDeletionConfirmationProps = {
  deleteNote: (unavailability: NoteData) => void;
  reFetchNotes: () => void;
  note: NoteData;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};
export const NoteDeletionConfirmation = ({
  deleteNote,
  reFetchNotes,
  note,
  isOpen,
  setIsOpen,
}: NoteDeletionConfirmationProps) => {
  const { t } = useTranslation();
  const [deletionOption, setDeletionOption] = useState<DeletionMode>(DeletionMode.SINGLE);
  const [recurrentCount, setRecurrentCount] = useState<number | null>(null);

  const getRecurrentNoteCount = async (recurrentId?: string) => {
    try {
      if (!recurrentId) {
        return;
      }
      const count = await NoteService.getRecurrentNoteCount(recurrentId);
      setRecurrentCount(count);
    } catch (error) {
      console.error('Failed to fetch recurrent unavailability count', error);
    }
  };
  useEffect(() => {
    setDeletionOption(DeletionMode.SINGLE);
    void getRecurrentNoteCount(note.recurrent_note_id);
  }, [note?.id]);
  const hasRecurrentNote = note.recurrent_note_id;
  return (
    <ConfirmationDialog
      title={t('calendar.note_dialog.deletetitle')}
      content={
        hasRecurrentNote ? (
          <Grid container flexDirection="column">
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup value={deletionOption} onChange={(e) => setDeletionOption(e.target.value as DeletionMode)}>
                  <FormControlLabel
                    value={DeletionMode.SINGLE}
                    control={<Radio />}
                    label={t('orders.note_dialog.single')}
                  />
                  <FormControlLabel
                    value={DeletionMode.FROM_NOW_ON}
                    control={<Radio />}
                    label={
                      <Grid container gap={1} alignItems="baseline">
                        {t('orders.note_dialog.repeating')}
                        {recurrentCount !== null && (
                          <Typography variant="caption" color="grey">
                            {t('calendar.note_dialog.recurrent_count', { count: recurrentCount })}
                          </Typography>
                        )}
                      </Grid>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid>
              <Typography>{t('calendar.delete_note.description')}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Typography>{t('calendar.delete_note.final')}</Typography>
          </Grid>
        )
      }
      isOpen={isOpen}
      onClose={async (actionConfirmed) => {
        setIsOpen(false);
        if (actionConfirmed) {
          if (deletionOption === DeletionMode.SINGLE) {
            await NoteService.deleteNote(note.id);
            deleteNote(note);
          } else {
            await NoteService.deleteNoteRecurrent(note.recurrent_note_id!);
            reFetchNotes();
          }
        }
      }}
    />
  );
};
