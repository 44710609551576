import { Grid, IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './Orders.scss';
import { Delete, Edit, Update } from '@mui/icons-material';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import Typography from '@mui/material/Typography';
import TimeUtils from '../common/TimeUtils';
import './UpcomingOrders.scss';
import { useCalendar } from '../calendar/CalendarContext';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import { AccountData, OrderData, OrderService, OrderStatus } from '../api';
import { WorkshopCircle } from '../common/ui/WorkshopCircle';
import { useAuth } from '../session/InternalAuthProvider';
import { useLanguage } from '../common/GeneralUtils';
import ConfirmationDialog from '../common/ui/ConfimationDialog';
import EditOrderDialog from '../calendar/dialogs/EditOrderDialog/EditOrderDialog';
import { CellProps, useTable } from '../table/useTable';
import { formatDate } from '../common/formatDate';
import { CalendarView, DATE_FORMAT } from '../calendar/CalendarWrapper';

type OrdersTableProps = {
  fetchingMethod: (page?: number, perPage?: number, search?: string, statuses?: OrderStatus[]) => Promise<OrderData[]>;
  opId: string;
  hideActions?: boolean;
};
export const OrdersTable = ({ fetchingMethod, opId, hideActions }: OrdersTableProps) => {
  const { t } = useTranslation();
  const { authState } = useAuth();
  const language = useLanguage();
  const [selectedOrder, setSelectedOrder] = useState<OrderData | undefined>();
  const [deleteOrderIsOpen, setDeleteOrderIsOpen] = useState(false);
  const account = authState.account as unknown as AccountData;
  const { readOnly } = UseAuthPermissions();
  const { shortDateFormat } = formatDate(account);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetcher = async ({ search, page, perPage }: { search?: string; page: number; perPage: number }) =>
    fetchingMethod(page, perPage, search);

  // @ts-ignore
  const { openEditOrderDialog, editOrderDialogState, closeEditOrderDialog } = useCalendar();
  const selfServeEditsEnabled = useFeatureFlagEnabled('self-serve-edits');

  function formatCreatedAt(created_at: string) {
    const createdAt = TimeUtils.getLocalTime(created_at, account.timezone);
    const isToday = createdAt.isSame(moment(), 'day');
    return (
      <Typography
        variant={isToday ? 'body1' : 'body2'}
        color={isToday ? '#4abd00' : undefined}
        fontWeight={isToday ? 700 : undefined}>
        {createdAt.format(isToday ? 'HH:mm' : 'DD MMM')}
      </Typography>
    );
  }

  const tableDetails: CellProps<OrderData>[] = [
    {
      id: 'name',
      alignment: 'center',
      label: t('orders.name'),
      render: (order: OrderData) => `${order.firstname} ${order.lastname}`,
    },
    {
      id: 'createdAt',
      alignment: 'center',
      label: t('orders.date'),
      render: (order: OrderData) => formatCreatedAt(order.created_at),
    },
    {
      id: 'numPersons',
      alignment: 'center',
      label: t('orders.num_persons'),
      render: (order: OrderData) => order.persons.toString(),
    },
    {
      id: 'orderDate',
      alignment: 'center',
      label: t('order_date'),
      render: (order: OrderData) => {
        const momentDate = moment(order.date);
        const calendarView = localStorage.getItem('webook-calendarview');
        // @ts-ignore
        const weekStart = momentDate.clone().startOf(calendarView === CalendarView.THREE_DAY ? 'day' : calendarView);
        return (
          <Link to={`/dashboard/calendar/${weekStart.format(DATE_FORMAT)}?entityid=${order.parent_id || order.id}`}>
            {momentDate.format(shortDateFormat)}
          </Link>
        );
      },
    },
    {
      id: 'phone',
      alignment: 'center',
      label: t('orders.phone'),
      render: (order: OrderData) => order.phone,
    },
    {
      id: 'email',
      alignment: 'center',
      label: t('orders.email'),
      render: (order: OrderData) => <Typography style={{ textTransform: 'none' }}>{order.email}</Typography>,
    },
    {
      id: 'workshop',
      alignment: 'center',
      label: t('orders.workshop'),
      render: (order: OrderData) => (
        <WorkshopCircle account={account} orderPageId={order.order_page_id} language={language} withTooltip />
      ),
    },
    {
      id: 'price',
      alignment: 'center',
      label: t('orders.price'),
      shouldUsePriceRenderer: true,
      width: 100,
      render: (_: OrderData) => {},
    },
    {
      id: 'orderNumber',
      alignment: 'center',
      label: t('order_number'),
      render: (order: OrderData) => `#${order.order_number}`,
    },
    {
      id: 'duration',
      alignment: 'center',
      label: t('orders.duration'),
      render: (order: OrderData) => TimeUtils.formatDuration(order.duration, t),
    },
    {
      id: 'status',
      alignment: 'center',
      label: t('orders.status'),
      render: (order: OrderData) => t(`order_status.${order.status.toLowerCase()}`),
    },
    {
      id: 'update',
      alignment: 'left',
      hideCell: hideActions,
      width: 30,
      noPadding: true,
      render: (order: OrderData) => (
        <a
          href={
            !readOnly && selfServeEditsEnabled && order.status !== OrderStatus.CONVERTED_TO_VOUCHER
              ? `${window.location.origin}/manage-booking?orderid=${order.id}`
              : undefined
          }
          target="_blank"
          rel="noopener noreferrer">
          <Tooltip
            title={readOnly ? t('noPermissions') : selfServeEditsEnabled ? t('amend') : t('amend_not_enabled')}
            arrow>
            <span>
              <IconButton disabled={readOnly || order.status === OrderStatus.CONVERTED_TO_VOUCHER}>
                <Update />
              </IconButton>
            </span>
          </Tooltip>
        </a>
      ),
    },
    {
      id: 'edit',
      alignment: 'left',
      width: 30,
      noPadding: true,
      render: (order: OrderData) => (
        <Tooltip title={readOnly ? t('noPermissions') : t('edit')}>
          <span>
            <IconButton
              disabled={readOnly || order.status === OrderStatus.CONVERTED_TO_VOUCHER}
              className="action-icon"
              onClick={() => {
                openEditOrderDialog(order, order.order_page_id, order.parent_id);
              }}>
              <Edit />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
    {
      id: 'delete',
      alignment: 'left',
      hideCell: hideActions,
      width: 30,
      noPadding: true,
      render: (order: OrderData) => (
        <Tooltip title={readOnly ? t('noPermissions') : t('delete')}>
          <span>
            <IconButton
              disabled={readOnly || order.status === OrderStatus.CONVERTED_TO_VOUCHER}
              className="action-icon"
              onClick={() => {
                setSelectedOrder(order);
                setDeleteOrderIsOpen(true);
              }}>
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  const { mutate, data, TableComponent } = useTable<OrderData>({
    opId,
    fetcher,
    tableDetails,
  });
  return (
    <Grid container>
      {TableComponent}
      <EditOrderDialog
        editOrderDialogState={editOrderDialogState}
        closeEditOrderDialog={closeEditOrderDialog}
        updateOrder={(orderToUpdate: OrderData) => {
          const orders = data?.filter((x) => x.id !== selectedOrder?.id) || [];
          void mutate([...orders, orderToUpdate]);
        }}
      />
      <ConfirmationDialog
        title={t('calendar.delete_order.title')}
        content={t('calendar.delete_order.description')}
        isOpen={deleteOrderIsOpen}
        onClose={async (actionConfirmed) => {
          if (actionConfirmed && selectedOrder) {
            await OrderService.deleteOrder(selectedOrder.id);
            const orders = data?.filter((x) => x.id !== selectedOrder.id) || [];
            void mutate(orders);
          }
          setDeleteOrderIsOpen(false);
        }}
      />
    </Grid>
  );
};
