import * as Sentry from '@sentry/react';

const ErrorBoundary = ({ children }: { children: any }) => {
  const handleCaptureException = (error: any, errorInfo: any) => {
    // Custom error handling
    console.error('Error captured by boundary:', error);

    // Send to Sentry
    Sentry.captureException(error, { extra: errorInfo });
  };

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred.</p>} onError={handleCaptureException}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
