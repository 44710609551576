import React from 'react';
import { KeyedMutator } from 'swr/_internal';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import { AccountData, EmailBlockData, EmailTemplateType, Language, OrderPageData } from '../../api';
import DragDropProvider from './components/DragDropProvider';
import { EmailTemplateTemplateDnd } from './components/EmailTemplateTemplateDnd';
import { createDraggableItem } from './components/DraggableItems';
import { AddItemsPanel } from './components/AddItemsPanel';
import { UpdateTemplateSubmitButton } from './components/UpdateTemplateSubmitButton';
import { EmailTemplatesStaticContent } from '../utils/resolveTemplate';
import { TestEmailButton } from './components/TestEmailButton';
import { getOrderPages } from '../../common/AccountUtils';

type ConfirmationTemplateEditorProps = {
  selectedLanguage: Language;
  setIsUpdatedSnackOpen: (value: boolean) => void;
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  templateWithDefaults?: EmailTemplatesStaticContent;
  templateType: EmailTemplateType;
  orderPageIdOverride?: string;
  isEditingTemplatesEnabled: boolean;
};
export const EmailTemplateEditor = ({
  selectedLanguage,
  setIsUpdatedSnackOpen,
  account,
  updateLocalAccount,
  templateWithDefaults,
  templateType,
  orderPageIdOverride,
  isEditingTemplatesEnabled,
}: ConfirmationTemplateEditorProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const accountEmailsCommunicationSettings = account.email_communication_settings;
  const orderPageOverrideCommunicationsSettings = getOrderPages(account).find(
    (x: OrderPageData) => x.id === orderPageIdOverride,
  )?.email_communication_settings;
  const orderPageTemplateOverrides =
    orderPageOverrideCommunicationsSettings?.[templateType]?.custom_content?.[selectedLanguage] || [];
  const initialItems =
    orderPageIdOverride && !isEmpty(orderPageTemplateOverrides)
      ? orderPageTemplateOverrides
      : accountEmailsCommunicationSettings?.[templateType]?.custom_content?.[selectedLanguage] || [];
  const initialDraggableItems = initialItems.map((item: EmailBlockData) => createDraggableItem(item));
  const templateBackgroundColor = isEditingTemplatesEnabled ? '#ffffff' : '#D3D3D3';
  return (
    <DragDropProvider initialItems={initialDraggableItems} cacheKey={`${templateType}-${selectedLanguage}`}>
      <Grid container gap={4} flexDirection={isSmallScreen ? 'column' : 'row'} justifyContent="center">
        <Grid container gap={2} justifyContent="center">
          {!isSmallScreen && (
            <UpdateTemplateSubmitButton
              updateLocalAccount={updateLocalAccount}
              setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
              selectedLanguage={selectedLanguage}
              templateType={templateType}
              orderPageIdOverride={orderPageIdOverride}
              isEditingTemplatesEnabled={isEditingTemplatesEnabled}
            />
          )}

          <TestEmailButton
            selectedLanguage={selectedLanguage}
            templateType={templateType}
            orderPageIdOverride={orderPageIdOverride}
          />
        </Grid>
        <Grid container gap={2}>
          {!isSmallScreen && (
            <Grid item xs={'auto'} style={{ flex: '0 0 auto' }}>
              <AddItemsPanel isDisabled={!isEditingTemplatesEnabled} />
            </Grid>
          )}
          <Grid
            container
            maxWidth="702px"
            style={{
              overflowY: isSmallScreen ? 'visible' : 'auto',
              flex: '1 1 auto',
              height: '80vh',
            }}>
            <Grid
              container
              border={`1px solid ${accountEmailsCommunicationSettings?.border_color}`}
              borderRadius={2}
              style={{
                background: templateBackgroundColor,
              }}
              maxWidth="702px"
              p={4}>
              <Grid
                container
                style={{
                  minWidth: isSmallScreen ? undefined : '600px',
                  direction: selectedLanguage === 'he' ? 'rtl' : 'ltr',
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    templateWithDefaults?.headerHtml.replaceAll(
                      'background-color:#ffffff',
                      `background-color:${templateBackgroundColor}`,
                    ) || '',
                }}
              />
              <EmailTemplateTemplateDnd
                selectedLanguage={selectedLanguage}
                borderColor={accountEmailsCommunicationSettings?.border_color}
              />
              <Grid
                container
                style={{
                  minWidth: isSmallScreen ? undefined : '600px',
                  direction: selectedLanguage === 'he' ? 'rtl' : 'ltr',
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    templateWithDefaults?.footerHtml.replaceAll(
                      'background-color:#ffffff',
                      `background-color:${templateBackgroundColor}`,
                    ) || '',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DragDropProvider>
  );
};
