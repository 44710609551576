import moment from 'moment';

const FinalDateDetails = (props) => {
  const { date, time, duration } = props;
  if (!date) {
    return null;
  }

  const endTime = moment(time);
  endTime.add(duration, 'seconds');

  return (
    <div className="final-date-details">
      {date.format('dddd, MMMM, DD')}
      &nbsp;&nbsp;&nbsp;⋅&nbsp;&nbsp;&nbsp;
      <strong>
        {time.format('HH:mm')} - {endTime.format('HH:mm')}
      </strong>
    </div>
  );
};

export default FinalDateDetails;
