import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useEffect, useRef, useState } from 'react';

type StripePaymentFrameProps = {
  publishableKey: string;
  clientSecret: string;
  stripeAccountId?: string;
};
export const StripePaymentFrame = ({ publishableKey, clientSecret, stripeAccountId }: StripePaymentFrameProps) => {
  const stripeRef = useRef<Promise<Stripe | null> | null>(null);
  // Ensure Stripe is initialized only once
  useEffect(() => {
    if (publishableKey && !stripeRef.current) {
      stripeRef.current = loadStripe(publishableKey, { stripeAccount: stripeAccountId });
    }
  }, [publishableKey]);
  // Force re-render of the EmbeddedCheckout component when clientSecret changes
  const [checkoutKey, setCheckoutKey] = useState(0);

  useEffect(() => {
    if (clientSecret) {
      setCheckoutKey((prevKey) => prevKey + 1);
    }
  }, [clientSecret]);

  if (!stripeRef.current) {
    return null;
  }
  return (
    <EmbeddedCheckoutProvider key={checkoutKey} stripe={stripeRef.current} options={{ clientSecret }}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};
