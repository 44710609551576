import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { NavigationItemButton } from './NavigationItemButton';
import { NavigationHeader, NavigationItem } from './MainSidebar';
import { MenuButton } from './MenuButton';

type NavigationMainButtonProps = {
  navigationHeader: NavigationHeader;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
};
export const NavigationHeaderButton = ({ navigationHeader, setIsSidebarOpen }: NavigationMainButtonProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Grid container flexDirection="column" alignContent="flex-start" alignItems="flex-start">
      <MenuButton
        setIsExpanded={setIsExpanded}
        name={navigationHeader.name}
        icon={navigationHeader.icon}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      {isExpanded &&
        navigationHeader.navigationSubItems?.map((navigationItem: NavigationItem | undefined) =>
          navigationItem ? (
            <Grid container>
              <NavigationItemButton
                key={navigationItem.key}
                setIsSidebarOpen={setIsSidebarOpen}
                navigationItem={navigationItem}
                isSubMenuItem
              />
            </Grid>
          ) : null,
        )}
    </Grid>
  );
};
