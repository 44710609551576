import React, { useState } from 'react';
import { AccordionDetails, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrderData, OrderPageData } from '../../../../../api';
import PersonalDetailsStep, { PageContext } from '../../../../../order_page/steps/PersonalDetailsStep';
import { OrderPageIntent } from '../../../../../order_page/OrderPage';
import { Accordion, AccordionPanelsNames, AccordionSummary } from '../Accordion';

type PersonalDetailsPanelProps = {
  orderOrVoucher: Partial<OrderData>;
  personalDetailsFormRef: React.RefObject<any>;
  orderPage: OrderPageData;
  isOneOfVoucherIntents?: boolean;
  setOrderCustomDetails: (details: any) => void;
  expanded?: AccordionPanelsNames;
  handleChange: any;
};
export const PersonalDetailsPanel = ({
  orderOrVoucher,
  personalDetailsFormRef,
  orderPage,
  isOneOfVoucherIntents,
  setOrderCustomDetails,
  expanded,
  handleChange,
}: PersonalDetailsPanelProps) => {
  const { t } = useTranslation();
  const [previewName, setPreviewName] = useState(`${orderOrVoucher?.firstname} ${orderOrVoucher?.lastname}`);
  return (
    <Accordion
      onChange={handleChange}
      expandedPanelName={expanded}
      panelName={AccordionPanelsNames.PersonalDetailsPanel}>
      <AccordionSummary title={t('calendar.edit_order.personal')} summaryComponent={<Grid>{previewName}</Grid>} />
      <AccordionDetails>
        <PersonalDetailsStep
          formRef={personalDetailsFormRef}
          orderPage={orderPage}
          firstname={orderOrVoucher.firstname}
          lastname={orderOrVoucher.lastname}
          email={orderOrVoucher.email}
          phone={orderOrVoucher.phone}
          custom_personal_details={orderOrVoucher.custom_personal_details}
          orderPageIntent={isOneOfVoucherIntents ? OrderPageIntent.GIFT_CARD : OrderPageIntent.BUY_NOW}
          setOrderCustomDetails={setOrderCustomDetails}
          pageContext={PageContext.ADMIN}
          setPreviewName={setPreviewName}
          orderCustomDetails={orderOrVoucher.custom_details}
        />
      </AccordionDetails>
    </Accordion>
  );
};
