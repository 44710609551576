/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {
  /**
   * Get Paid Transaction Reports
   * @param language Language of the report, default is 'en'
   * @param createdDateFrom Filter orders created from this date
   * @param createdDateTo Filter orders created up to this date
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getOrdersReport(
    language: string = 'en',
    createdDateFrom?: string,
    createdDateTo?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/reports/orders',
      query: {
        language: language,
        created_date_from: createdDateFrom,
        created_date_to: createdDateTo,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Workshop Reports
   * @param language Language of the report, default is 'en'
   * @param createdDateFrom Filter orders created from this date
   * @param createdDateTo Filter orders created up to this date
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWorkshopsReport(
    language: string = 'en',
    createdDateFrom?: string,
    createdDateTo?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/reports/workshops',
      query: {
        language: language,
        created_date_from: createdDateFrom,
        created_date_to: createdDateTo,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
