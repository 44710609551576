import { Alert, Grid, IconButton, Snackbar, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../orders/Orders.scss';
import { Delete, Edit, Email, Link as MuiLinkIcon, Redeem } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import '../orders/UpcomingOrders.scss';
import ConfirmationNumber from '@mui/icons-material/ConfirmationNumber';
import { AccountData, VoucherOut, VoucherService, VoucherStatus } from '../api';
import { getVoucherPaymentStatusTextOrIcon, getVoucherStatus } from './utils';
import OrderBoxDialog from '../calendar/calendar-items/OrderBoxDialog';
import { CellProps, useTable } from '../table/useTable';
import VouchersActions from './VouchersActions';
import { priceFormatter } from '../order_page/utils';
import { useAuth } from '../session/InternalAuthProvider';
import { useCalendar } from '../calendar/CalendarContext';
import EditOrderDialog from '../calendar/dialogs/EditOrderDialog/EditOrderDialog';
import { WorkshopCircle } from '../common/ui/WorkshopCircle';
import { useLanguage } from '../common/GeneralUtils';
import TimeUtils from '../common/TimeUtils';
import { formatDate } from '../common/formatDate';
import ConfirmationDialog from '../common/ui/ConfimationDialog';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import CopyToClipboardButton from '../calendar/dialogs/CopyToClipboardButton';

export const voucherFetcher = async ({ search, page, perPage }: { search?: string; page: number; perPage: number }) =>
  VoucherService.getVouchers(page, perPage, search);

const VouchersTable = React.memo(() => {
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();

  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const language = useLanguage();
  const [deleteVoucherIsOpen, setDeleteVoucherIsOpen] = useState(false);
  const [sendVoucherDialogIsOpen, setSendVoucherDialogIsOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<VoucherOut | undefined>(undefined);
  const [sendToEmail, setSendToEmail] = useState<string | undefined>();
  const [emailSentIsOpen, setEmailSentIsOpen] = useState(false);

  const [orderOpen, setOrderOpen] = useState(false);
  const [orderId, setOrderId] = useState<string | undefined>(undefined);

  // @ts-ignore
  const { editOrderDialogState, closeEditOrderDialog, openEditOrderDialog } = useCalendar();

  const tableDetails: CellProps<VoucherOut>[] = [
    {
      id: 'createdat',
      alignment: 'center',
      label: t('vouchers.createdat'),
      render: (voucher: VoucherOut) => (
        <Typography>
          {TimeUtils.getLocalTime(voucher.created_at, account.timezone).format(
            `${formatDate(account).shortDateFormat} HH:mm`,
          )}
        </Typography>
      ),
    },
    {
      id: 'name',
      alignment: 'center',
      label: t('vouchers.name'),
      render: (voucher: VoucherOut) => `${voucher.firstname} ${voucher.lastname}`,
    },
    {
      id: 'num_persons',
      alignment: 'center',
      label: t('vouchers.num_persons'),
      render: (voucher: VoucherOut) => voucher.persons,
    },
    {
      id: 'phone_of_buyer',
      alignment: 'center',
      label: t('vouchers.phone_of_buyer'),
      render: (voucher: VoucherOut) => voucher.phone,
    },
    {
      id: 'workshop',
      alignment: 'center',
      label: t('orders.workshop'),
      render: (voucher: VoucherOut) => (
        <WorkshopCircle account={account} orderPageId={voucher.order_page_id} language={language} withTooltip />
      ),
    },
    {
      id: 'price',
      alignment: 'center',
      label: t('vouchers.price'),
      render: (voucher: VoucherOut) => priceFormatter(voucher.price, authState.account as unknown as AccountData),
    },
    {
      id: 'order_number',
      alignment: 'center',
      label: t('order_number'),
      render: (voucher: VoucherOut) => `#${voucher.order_number}`,
    },
    {
      id: 'paymentStatus',
      alignment: 'center',
      label: t('vouchers.paymentStatus'),
      render: (voucher: VoucherOut) => getVoucherPaymentStatusTextOrIcon(voucher.payment_status, t),
    },
    {
      id: 'status',
      alignment: 'center',
      label: t('vouchers.status'),
      render: (voucher: VoucherOut) => getVoucherStatus(voucher.status, t),
    },
    {
      id: 'orderlink',
      alignment: 'center',
      label: t('vouchers.orderlink'),
      render: (voucher: VoucherOut) =>
        voucher.visits_used?.[0] ? (
          <Grid
            sx={{ cursor: 'pointer' }}
            item
            onClick={() => {
              setOrderOpen(true);
              if (voucher.visits_used?.[0]) {
                setOrderId(voucher.visits_used?.[0]);
              }
            }}>
            <Tooltip title={t('info')} arrow>
              <ConfirmationNumber style={{ color: '#bababa' }} />
            </Tooltip>
          </Grid>
        ) : (
          ''
        ),
    },
    {
      id: 'actions',
      alignment: 'center',
      width: 208,
      label: t('vouchers.details'),
      render: (voucher: VoucherOut) => {
        const shouldDisableActions = voucher.status !== VoucherStatus.ACTIVE || readOnly;
        const voucherLink = `${window.location.origin}/order/${voucher.account_id}/${voucher.experience_id}/${voucher.order_page_id}?vouchercode=${voucher.code}`;

        return (
          <Grid container direction="row" justifyContent="flex-end" padding="2px">
            <Tooltip title={voucher.status !== VoucherStatus.ACTIVE ? t('vouchers.redeemdisabled') : t('redeem')} arrow>
              <span>
                <IconButton
                  onClick={() => openEditOrderDialog(null, voucher.order_page_id, null, null, voucher)}
                  disabled={shouldDisableActions}>
                  <Redeem />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={voucher.status !== VoucherStatus.ACTIVE ? t('vouchers.redeemdisabled') : t('redeemptionLink')}
              arrow>
              <Grid container width="27px" alignItems="center" mx={1}>
                <CopyToClipboardButton
                  text={voucherLink}
                  IconComponent={MuiLinkIcon}
                  color={voucher.status !== VoucherStatus.ACTIVE ? 'grey' : undefined}
                />
              </Grid>
            </Tooltip>
            <Tooltip title={shouldDisableActions ? t('noPermissions') : t('sendMessage')} arrow>
              <span>
                <IconButton
                  disabled={shouldDisableActions}
                  onClick={() => {
                    setSelectedVoucher(voucher);
                    setSendToEmail(voucher.email);
                    setSendVoucherDialogIsOpen(true);
                  }}>
                  <Email />
                </IconButton>
              </span>
            </Tooltip>
            <IconButton
              disabled={shouldDisableActions}
              onClick={() => {
                openEditOrderDialog(null, voucher.order_page_id, null, null, null, false, voucher);
              }}>
              <Tooltip title={t('edit')} arrow>
                <Edit />
              </Tooltip>
            </IconButton>
            <Tooltip
              title={voucher.status !== VoucherStatus.ACTIVE ? t('vouchers.deleteDisabled') : t('vouchers.archive')}
              arrow>
              <span>
                <IconButton
                  onClick={() => {
                    setSelectedVoucher(voucher);
                    setDeleteVoucherIsOpen(true);
                  }}
                  disabled={shouldDisableActions}>
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];
  const {
    mutate: mutateVouchers,
    data,
    TableComponent,
  } = useTable<VoucherOut>({
    actionsComponent: <VouchersActions />,
    defaultRowsPerPage: 10,
    opId: 'getVouchers',
    fetcher: voucherFetcher,
    tableDetails,
  });
  const vouchersList = data || [];

  const archiveVoucher = async () => {
    if (selectedVoucher) {
      await VoucherService.archiveVoucher({ voucher_id: selectedVoucher.id });
      const otherVouchers = vouchersList.filter((x) => x.id !== selectedVoucher.id);
      await mutateVouchers([...otherVouchers, { ...selectedVoucher, status: VoucherStatus.ARCHIVED }]);
    }
  };
  const redeemVoucherCallback = (voucher: VoucherOut) => {
    const otherVouchers = vouchersList.filter((x) => x.id !== voucher.id);
    void mutateVouchers([...otherVouchers, { ...voucher, status: VoucherStatus.USED }]);
  };

  return (
    <Grid container>
      {TableComponent}
      <OrderBoxDialog open={orderOpen} setOpen={setOrderOpen} orderId={orderId} />
      <EditOrderDialog
        editOrderDialogState={editOrderDialogState}
        closeEditOrderDialog={() => {
          void mutateVouchers();
          void closeEditOrderDialog();
        }}
        redeemVoucherCallback={redeemVoucherCallback}
      />
      <ConfirmationDialog
        title={t('vouchers.archiveVoucher.title')}
        content={t('vouchers.archiveVoucher.description')}
        isOpen={deleteVoucherIsOpen}
        onClose={async (actionConfirmed) => {
          if (actionConfirmed && selectedVoucher) {
            await archiveVoucher();
          }
          setDeleteVoucherIsOpen(false);
        }}
      />
      <ConfirmationDialog
        loadingButton
        ctaText={t('vouchers.sendVoucher.sendEmail')}
        confirmButtonColor="primary"
        title={t('vouchers.sendVoucher.title')}
        content={
          <Grid container flexDirection="column">
            <Typography>{t('vouchers.sendVoucher.chooseEmail')}</Typography>
            <Grid maxWidth="200px" mt={2}>
              <TextField size="small" value={sendToEmail} onChange={(e) => setSendToEmail(e.target.value)} />
            </Grid>
          </Grid>
        }
        isOpen={sendVoucherDialogIsOpen}
        onClose={async (actionConfirmed) => {
          if (actionConfirmed && selectedVoucher) {
            await VoucherService.sendVoucher({ voucher_id: selectedVoucher.id, email: sendToEmail });
            setEmailSentIsOpen(true);
          }
          setSendVoucherDialogIsOpen(false);
        }}
      />
      <Snackbar
        open={emailSentIsOpen}
        onClose={() => setEmailSentIsOpen(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setEmailSentIsOpen(false)} severity="success">
          {t('vouchers.sendVoucher.emailSentSuccess')}
        </Alert>
      </Snackbar>
    </Grid>
  );
});

export default VouchersTable;
