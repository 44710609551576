import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Grid, Grow, Switch, TextField, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { NoteData, NoteService, RepeatingNoteRequestData } from '../../../api';
import { useAuth } from '../../../session/InternalAuthProvider';
import { UseRepeatingDays } from '../../components/UseRepeatingDays';
import TitledDrawer from '../../../common/ui/TitledDrawer';
import { NoteUpdateDialogConfirmation } from './NoteUpdateDialogConfirmation';
import { DeletionMode } from '../UserAvailabilityDialog/UserAvailabilityDeletionConfirmation';
import { useNotes } from '../../swr/useNotes';
import { NoteDeletionConfirmation } from './NoteDeletionConfirmation';

const DATE_FORMAT = 'YYYY-MM-DD';
type NoteDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  note?: NoteData;
  calendarStartDate: string;
  calendarEndDate: string;
};
const NoteDialog = ({ open, setOpen, note, calendarStartDate, calendarEndDate }: NoteDialogProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false);
  const [isNoteDeletionOpen, setIsNoteDeletionOpen] = useState(false);
  const { t } = useTranslation();

  const [allDay, setAllDay] = useState(true);
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(
    note?.start_time ? moment.unix(note?.start_time) : moment().startOf('day'),
  );
  const [startDateOpen, setStartDateOpen] = useState(false);

  const [startTime, setStartTime] = useState(moment().hours(9).minutes(0).seconds(0));
  const [endTime, setEndTime] = useState(moment().hours(17).minutes(0).seconds(0));
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);
  const [repeating, setRepeating] = useState(false);

  const { authState } = useAuth();
  const hasRecurrentNote = !!note?.recurrent_note_id;
  const [loading, setLoading] = useState(false);
  const {
    addItems: addNotes,
    updateItem: updateNotes,
    reFetch: refetchNotes,
    deleteItem: deleteNote,
  } = useNotes({
    startDate: calendarStartDate,
    endDate: calendarEndDate,
  });
  const { RepeatingDaysComponent, days, endDate, setEndDate } = UseRepeatingDays({ minDate: startDate });
  const isUpdatingNote = !!note;

  useEffect(() => {
    if (!note) {
      // reset all fields
      setDescription('');
      setStartDate(moment().startOf('day'));
      setEndDate(moment().startOf('day'));
      setStartDateOpen(false);
      setStartTime(moment().hours(9).minutes(0).seconds(0));
      setEndTime(moment().hours(17).minutes(0).seconds(0));
      setStartTimeOpen(false);
      setEndTimeOpen(false);
      setRepeating(false);

      return;
    }
    setDescription(note.description);
    setAllDay(note.all_day);
    setStartDate(moment(note.date));
    setStartTime(moment.unix(note.start_time).tz('utc'));
    setEndTime(moment.unix(note.end_time).tz('utc'));
  }, [note, open]);
  const handleClose = (e?: any, reason?: any) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleAllDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllDay(event.target.checked);
  };

  const getRepeatingData = (): RepeatingNoteRequestData | undefined => {
    if (!repeating) {
      return undefined;
    }

    return {
      enabled: repeating,
      days,
    };
  };
  const baseCreateUpdateData = {
    all_day: allDay,
    description,
    start_date: startDate.format(DATE_FORMAT),
    end_date: endDate.format(DATE_FORMAT),
    start_time: allDay ? 0 : startTime.hours() * 3600 + startTime.minutes() * 60,
    end_time: allDay ? 0 : endTime.hours() * 3600 + endTime.minutes() * 60,
  };
  const updateNote = async () => {
    const updated = await NoteService.editNote({
      id: (note as NoteData).id,
      date: startDate.format(DATE_FORMAT),
      ...baseCreateUpdateData,
    });
    updateNotes(updated);
    setLoading(false);
    setUpdateDialogIsOpen(false);
    handleClose();
  };
  const updateRepeatingNote = async () => {
    await NoteService.editNoteRecurrent({
      recurrent_note_id: note!.recurrent_note_id!,
      date: startDate.format(DATE_FORMAT),
      ...baseCreateUpdateData,
    });
    void refetchNotes();
    setLoading(false);
    setUpdateDialogIsOpen(false);
    handleClose();
  };
  const save = async () => {
    if (isUpdatingNote && hasRecurrentNote) {
      setUpdateDialogIsOpen(true);
      return;
    }
    setLoading(true);
    if (isUpdatingNote) {
      await updateNote();
    } else {
      const res = await NoteService.createNote({
        ...baseCreateUpdateData,
        repeating: getRepeatingData(),
      });
      addNotes(res);
      setLoading(false);
      handleClose();
    }
  };

  const title = isUpdatingNote ? t('calendar.note_dialog.edit_title') : t('calendar.note_dialog.title');
  return (
    <TitledDrawer onClose={handleClose} open={open} title={title}>
      <Grid
        container
        flexDirection="column"
        width={isSmallScreen ? window.innerWidth : '550px'}
        p={isSmallScreen ? '8px' : '16px'}
        pb="0px"
        overflow="hidden"
        height="100%">
        <Grid
          p="8px"
          container
          flexDirection="column"
          style={{ overflowY: 'scroll', maxHeight: 'calc(100% - 70px)' }}
          flexWrap="nowrap"
          height="100%"
          gap={2}>
          <Grid item>
            <TextField
              variant="outlined"
              sx={{ width: '231px' }}
              label={t('calendar.note_dialog.description')}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          <Grid>
            <DatePicker
              open={startDateOpen}
              onOpen={() => setStartDateOpen(true)}
              onClose={() => setStartDateOpen(false)}
              label={t('calendar.unavailability_dialog.date')}
              format="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue!);
                if (newValue?.isSameOrAfter(endDate)) {
                  setEndDate(newValue);
                }
              }}
              disablePast
            />
          </Grid>
          <Grid
            item
            borderRadius="8px"
            border="1px solid #c4c4c4"
            p={1}
            pb={allDay ? 1 : 2}
            maxWidth={allDay ? '231px' : undefined}>
            <Grid item>
              <FormControlLabel
                value="start"
                control={<Switch checked={allDay} onChange={handleAllDayChange} color="primary" />}
                label={t('calendar.unavailability_dialog.all_day')}
              />
            </Grid>

            {!allDay && (
              <Grow in={!allDay} timeout={1200}>
                <Grid container gap={2}>
                  <MobileTimePicker
                    minTime={moment().set({ hour: 7, minute: 0, second: 0, millisecond: 0 })}
                    ampm={false}
                    open={startTimeOpen}
                    onOpen={() => setStartTimeOpen(true)}
                    onClose={() => setStartTimeOpen(false)}
                    label={t('calendar.unavailability_dialog.start_time')}
                    value={startTime}
                    onChange={(newValue) => {
                      setStartTime(newValue!);
                    }}
                  />
                  <MobileTimePicker
                    minTime={startTime}
                    ampm={false}
                    open={endTimeOpen}
                    onOpen={() => setEndTimeOpen(true)}
                    onClose={() => setEndTimeOpen(false)}
                    label={t('calendar.unavailability_dialog.end_time')}
                    value={endTime}
                    onChange={(newValue) => {
                      setEndTime(newValue!);
                    }}
                  />
                </Grid>
              </Grow>
            )}
          </Grid>
          {!isUpdatingNote && (
            <Grid item borderRadius="8px" border="1px solid #c4c4c4" p={1} maxWidth={!repeating ? '231px' : undefined}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  checked={repeating}
                  label={t('calendar.note_dialog.repeating')}
                  onChange={(e: any) => {
                    setRepeating(e.target.checked);
                    if (!e.target.checked) {
                      setEndDate(startDate.clone().startOf('day'));
                    }
                  }}
                />
              </FormGroup>

              {repeating && (
                <Grow in={repeating} timeout={500}>
                  <Grid>
                    <RepeatingDaysComponent />
                  </Grid>
                </Grow>
              )}
            </Grid>
          )}
        </Grid>

        <Grid p={2} container justifyContent={isUpdatingNote ? 'space-between' : 'flex-end'}>
          {isUpdatingNote && (
            <Button
              variant="outlined"
              sx={{ textTransform: 'none' }}
              onClick={() => setIsNoteDeletionOpen(true)}
              color={'error'}>
              {t('delete')}
            </Button>
          )}
          <LoadingButton loading={loading} variant="contained" sx={{ textTransform: 'none' }} onClick={save}>
            {t('save')}
          </LoadingButton>
        </Grid>
      </Grid>
      <NoteUpdateDialogConfirmation
        dialogIsOpen={updateDialogIsOpen}
        setUpdateDialogIsOpen={setUpdateDialogIsOpen}
        recurrent_note_id={note?.recurrent_note_id}
        onSubmit={(deletionMode: DeletionMode) => {
          if (deletionMode === DeletionMode.SINGLE) {
            void updateNote();
          } else {
            void updateRepeatingNote();
          }
        }}
      />
      {note && (
        <NoteDeletionConfirmation
          note={note}
          reFetchNotes={refetchNotes}
          deleteNote={deleteNote}
          isOpen={isNoteDeletionOpen}
          setIsOpen={setIsNoteDeletionOpen}
        />
      )}
    </TitledDrawer>
  );
};

export default NoteDialog;
