import { Button, Grid, Tooltip } from '@mui/material';
import React from 'react';
import '../orders/Orders.scss';
import '../orders/UpcomingOrders.scss';
import { useTranslation } from 'react-i18next';
import { useCalendar } from '../calendar/CalendarContext';
import { UseAuthPermissions } from '../session/UseAuthPermissions';

// export type VouchersActionsProps = {  };
const VouchersActions = () => {
  const { t } = useTranslation();

  const { readOnly } = UseAuthPermissions();

  // @ts-ignore
  const { openEditOrderDialog } = useCalendar();
  return (
    <Grid container justifyContent="flex-end">
      <Tooltip title={readOnly ? t('noPermissions') : undefined}>
        <span>
          <Button
            disabled={readOnly}
            variant="contained"
            sx={{ textTransform: 'none', marginInlineStart: '20px' }}
            onClick={() => {
              openEditOrderDialog(null, null, null, null, null, true);
            }}>
            {t('vouchers.create')}
          </Button>
        </span>
      </Tooltip>
    </Grid>
  );
};

export default VouchersActions;
