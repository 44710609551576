import { Box, Grid, Icon, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import {
  AttachMoney,
  Close,
  Delete,
  Edit,
  Event,
  Numbers,
  People,
  Schedule,
  ShoppingCart,
  Subject,
  Title,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import './OrderBoxPopper.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TimeUtils from '../../common/TimeUtils';
import { useCalendar } from '../CalendarContext';
import { useAuth } from '../../session/InternalAuthProvider';
import { useLanguage } from '../../common/GeneralUtils';
import { AccountData, OrderData, OrderService } from '../../api';
import ResponsiveDialog from '../../common/ui/ResponsiveDialog';
import CircularLoader from '../../common/ui/CircularLoader';
import { priceFormatter } from '../../order_page/utils';
import { whatsAppLinkFormatter } from '../../common/whatsAppLinkFormatter';
import { getOrderPage } from '../../common/AccountUtils';

const OrderBoxDialog = ({
  orderData,
  orderId,
  open,
  setOpen,
}: {
  orderData?: OrderData;
  orderId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { t } = useTranslation();
  // @ts-ignore
  const { openEditOrderDialog, openDeleteOrderDialog } = useCalendar();
  // @ts-ignore
  const { authState } = useAuth();
  const language = useLanguage();
  const [isLoadingOrder, setIsLoadingOrder] = React.useState(false);
  const [order, setOrder] = React.useState<OrderData | undefined>(orderData);
  const account = authState.account as unknown as AccountData;
  const loadOrder = async (orderId: string) => {
    setIsLoadingOrder(true);
    const res = await OrderService.getOrder(orderId);
    setOrder(res);
    setIsLoadingOrder(false);
  };
  useEffect(() => {
    if (orderId) {
      void loadOrder(orderId);
    }
  }, [orderId]);

  if (!orderId || !order) {
    return null;
  }

  const orderPage = getOrderPage(authState.account, order.order_page_id);
  return (
    <ResponsiveDialog
      isOpen={open}
      setOpen={setOpen}
      content={
        <Paper elevation={24} className="order-box-popper-content height-100">
          {isLoadingOrder || !order ? (
            <CircularLoader />
          ) : (
            <Grid>
              <Stack direction="row" justifyContent="flex-end" padding="2px">
                <IconButton
                  onClick={() => {
                    openDeleteOrderDialog(order);
                    setOpen(false);
                  }}>
                  <Tooltip title={t('delete')} arrow>
                    <Delete />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => {
                    openEditOrderDialog(order);
                    setOpen(false);
                  }}>
                  <Tooltip title={t('edit')} arrow>
                    <Edit />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={() => setOpen(false)}>
                  <Tooltip title={t('close')} arrow>
                    <Close />
                  </Tooltip>
                </IconButton>
              </Stack>

              <Box className="details-container">
                <Box className="detail-container">
                  <div className="circle" />
                  <Box className="name-container">{`${order.firstname} ${order.lastname}`}</Box>
                </Box>

                <Box className="date-container">
                  {moment.unix(order.timestamp || 0).format('dddd, MMMM, DD')}
                  &nbsp;&nbsp;&nbsp;⋅&nbsp;&nbsp;&nbsp;
                  {moment
                    .unix(order.time || 0)
                    .tz('utc')
                    .format('HH:mm')}{' '}
                  -{' '}
                  {moment
                    .unix((order.time || 0) + order.duration)
                    .tz('utc')
                    .format('HH:mm')}
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">
                    <Title />
                  </div>
                  <Box>{orderPage.label[language]}</Box>
                </Box>

                {order.order_number != null && order.order_number !== 0 && (
                  <Box className="detail-container">
                    <div className="icon-container">
                      <Numbers />
                    </div>
                    <Tooltip title={t('order_number')} placement="top" arrow>
                      <Box>
                        <span>{t('order_number')}</span>
                        <span> &#35;</span>
                        <span>{order.order_number}</span>
                      </Box>
                    </Tooltip>
                  </Box>
                )}

                <Box className="detail-container">
                  <div className="icon-container">
                    <People />
                  </div>
                  <Box>
                    {order.persons} {t('calendar.order_popper.persons')}
                  </Box>
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">
                    <Schedule />
                  </div>
                  <Tooltip title={t('calendar.order_popper.duration')} placement="top" arrow>
                    <Box>{TimeUtils.formatDuration(order.duration, t)}</Box>
                  </Tooltip>
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">
                    <AttachMoney />
                  </div>
                  <Tooltip title={t('calendar.order_popper.price')} placement="top" arrow>
                    <Box>{priceFormatter(order.price, account)}</Box>
                  </Tooltip>
                </Box>

                {order.custom_details &&
                  orderPage.custom_details.map((custom_detail: any, index: number) => {
                    return (
                      <Box key={index} className="detail-container">
                        <div className="icon-container">
                          <Icon>{custom_detail.icon}</Icon>
                        </div>
                        <Tooltip title={custom_detail.name} placement="top" arrow>
                          <Box>
                            {custom_detail.type === 'str' && <span>{order.custom_details[custom_detail.name]}</span>}
                            {custom_detail.type === 'location' && (
                              <a
                                className="link"
                                target="_blank"
                                href={`https://maps.google.com/?q=term=${order.custom_details[custom_detail.name]}`}
                                rel="noreferrer">
                                {order.custom_details[custom_detail.name]}
                              </a>
                            )}
                            {custom_detail.type === 'bool' && (
                              <div>
                                {order.custom_details[custom_detail.name] ? (
                                  <span>{t('yes')}</span>
                                ) : (
                                  <span>{t('no')}</span>
                                )}
                              </div>
                            )}

                            {custom_detail.type === 'resourceInt' && (
                              <Typography>{order.custom_details[custom_detail.name]}</Typography>
                            )}
                          </Box>
                        </Tooltip>
                      </Box>
                    );
                  })}

                {order.custom_personal_details &&
                  orderPage.custom_personal_details.map((custom_detail: any, index: number) => {
                    if (order.custom_personal_details[custom_detail.id]) {
                      return (
                        <Box key={index} className="detail-container">
                          <div className="icon-container">
                            <Icon>note</Icon>
                          </div>
                          <Tooltip title={custom_detail.label[language]} placement="top" arrow>
                            <Box>
                              {custom_detail.type === 'multiline' && (
                                <span>{order.custom_personal_details[custom_detail.id]}</span>
                              )}
                            </Box>
                          </Tooltip>
                        </Box>
                      );
                    }
                    return undefined;
                  })}

                <Box className="detail-container multiple-details-container">
                  <div className="icon-container">
                    <Subject />
                  </div>
                  <Box className="multiple-details-container">
                    {t('calendar.order_popper.name')}: {order.firstname} {order.lastname}
                    <br />
                    {t('calendar.order_popper.email')}: {order.email}
                    <br />
                    {t('calendar.order_popper.phone')}:{' '}
                    <a className="link" target="_blank" href={whatsAppLinkFormatter(order.phone, '')} rel="noreferrer">
                      {order.phone}
                    </a>
                  </Box>
                </Box>

                <Tooltip title={t('calendar.order_popper.creation_time')} placement="top" arrow>
                  <Box className="detail-container">
                    <div className="icon-container">
                      <Event />
                    </div>
                    <Box>
                      {TimeUtils.getLocalTime(order.created_at, account.timezone).format('dddd, MMMM, DD')}
                      &nbsp;&nbsp;&nbsp;⋅&nbsp;&nbsp;&nbsp;
                      {TimeUtils.getLocalTime(order.created_at, account.timezone).format('HH:mm')}
                    </Box>
                  </Box>
                </Tooltip>

                <Box className="detail-container">
                  <div className="icon-container">
                    <ShoppingCart />
                  </div>
                  <Tooltip title={t('calendar.order_popper.status')} placement="top" arrow>
                    <Box sx={{ textTransform: 'capitalize' }}>{t(`order_status.${order.status.toLowerCase()}`)}</Box>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          )}
        </Paper>
      }
    />
  );
};

export default OrderBoxDialog;
