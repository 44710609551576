import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Grid, Grow, Switch, TextField, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import {
  AccountData,
  OrderPageData,
  OrderPageTypes,
  RepeatingUnavailabilityRequestData,
  UnavailabilityData,
  UnavailabilityDetailData,
  UnavailabilityService,
} from '../../../api';
import { useAuth } from '../../../session/InternalAuthProvider';
import { UnavailabilityOrderPagesMultiSelect } from './UnavailabilityOrderPagesMultiSelect';
import { UseRepeatingDays } from '../../components/UseRepeatingDays';
import TitledDrawer from '../../../common/ui/TitledDrawer';
import { useUnavailabilities } from '../../swr/useUnavailabilities';
import { getOrderPages } from '../../../common/AccountUtils';
import { UnvailabilityUpdateDialogConfirmation } from './UnvailabilityUpdateDialogConfirmation';
import { DeletionMode } from '../UserAvailabilityDialog/UserAvailabilityDeletionConfirmation';

export const MAX_PARALLEL_PERSON_CAPACITY = 'max_parallel_person_capacity';
export const CAPACITIES_BLOCKS_ARRAY = 'capacities_blocks_array';
export const STATIONS = 'stations';
const DATE_FORMAT = 'YYYY-MM-DD';
type UnavailabilityDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  unavailability?: UnavailabilityData;
  calendarStartDate: string;
  calendarEndDate: string;
};
const UnavailabilityDialog = ({
  open,
  setOpen,
  unavailability,
  calendarStartDate,
  calendarEndDate,
}: UnavailabilityDialogProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false);
  const { t } = useTranslation();

  const [allDay, setAllDay] = useState(true);
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(
    unavailability?.start_time ? moment.unix(unavailability?.start_time) : moment().startOf('day'),
  );
  const [startDateOpen, setStartDateOpen] = useState(false);

  const [startTime, setStartTime] = useState(moment().hours(9).minutes(0).seconds(0));
  const [endTime, setEndTime] = useState(moment().hours(17).minutes(0).seconds(0));
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);
  const [repeating, setRepeating] = useState(false);

  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const orderPages = getOrderPages(account);
  // blocking event order pages doesnt make sense as you should just lower the amount of participants.
  const privateOrdersPages = orderPages.filter((x: OrderPageData) => x.order_page_type !== OrderPageTypes.EVENT);
  const hasRecurrentUnavailability = !!unavailability?.recurrent_unavailability_id;
  const [unavailabilityDetails, setUnavailabilityDetails] = useState<UnavailabilityDetailData[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    addItems: addUavailabilities,
    updateItem: updateUavailability,
    reFetch: refetchUnavliability,
  } = useUnavailabilities({
    startDate: calendarStartDate,
    endDate: calendarEndDate,
  });
  const { RepeatingDaysComponent, days, endDate, setEndDate } = UseRepeatingDays({ minDate: startDate });
  const isUpdatingUnavailability = !!unavailability;
  const getInitialCustomDetails = (orderPageData: OrderPageData) => {
    const orderPage = privateOrdersPages.filter((o: any) => o.id === orderPageData.id)[0];
    const customDetails = {};
    for (const customDetail of orderPage.custom_details) {
      if (customDetail.cls === 'CustomResourceDetail') {
        // @ts-ignore
        customDetails[customDetail.name] = customDetail.max;
      }
    }
    if (orderPage.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities) {
      // @ts-ignore
      customDetails[CAPACITIES_BLOCKS_ARRAY] =
        // eslint-disable-next-line max-len
        orderPage.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities;
    }

    return customDetails;
  };
  useEffect(() => {
    if (!unavailability) {
      // reset all fields
      setDescription('');
      setStartDate(moment().startOf('day'));
      setEndDate(moment().startOf('day'));
      setStartDateOpen(false);
      setStartTime(moment().hours(9).minutes(0).seconds(0));
      setEndTime(moment().hours(17).minutes(0).seconds(0));
      setStartTimeOpen(false);
      setEndTimeOpen(false);
      setRepeating(false);
      setUnavailabilityDetails(
        privateOrdersPages.map((x: OrderPageData) => ({
          order_page_id: x.id,
          custom_details: getInitialCustomDetails(x),
        })),
      );
      return;
    }
    setDescription(unavailability.description);
    setAllDay(unavailability.all_day);
    setStartDate(moment(unavailability.date));
    setStartTime(moment.unix(unavailability.start_time).tz('utc'));
    setEndTime(moment.unix(unavailability.end_time).tz('utc'));
    const unavailability_details = unavailability.unavailability_details ? unavailability.unavailability_details : [];
    // for bc
    if (unavailability.order_page_id) {
      const oldCustomDetail = unavailability.custom_details;
      unavailability_details.push({ order_page_id: unavailability.order_page_id, custom_details: oldCustomDetail });
    }
    setUnavailabilityDetails(unavailability.unavailability_details ? unavailability.unavailability_details : []);
  }, [unavailability, open]);
  const handleClose = (e?: any, reason?: any) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleAllDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllDay(event.target.checked);
  };

  const getRepeatingData = (): RepeatingUnavailabilityRequestData | undefined => {
    if (!repeating) {
      return undefined;
    }

    return {
      enabled: repeating,
      days,
    };
  };
  const baseCreateUpdateData = {
    all_day: allDay,
    description,
    start_date: startDate.format(DATE_FORMAT),
    end_date: endDate.format(DATE_FORMAT),
    start_time: allDay ? 0 : startTime.hours() * 3600 + startTime.minutes() * 60,
    end_time: allDay ? 0 : endTime.hours() * 3600 + endTime.minutes() * 60,
    unavailability_details: unavailabilityDetails,
  };
  const updateUnavliability = async () => {
    const updated = await UnavailabilityService.editUnavailability({
      id: (unavailability as UnavailabilityData).id,
      date: startDate.format(DATE_FORMAT),
      ...baseCreateUpdateData,
    });
    updateUavailability(updated);
    setLoading(false);
    setUpdateDialogIsOpen(false);
    handleClose();
  };
  const updateRepeatingUnavliability = async () => {
    await UnavailabilityService.editUnavailabilityRecurrent({
      recurrent_unavailability_id: unavailability!.recurrent_unavailability_id!,
      date: startDate.format(DATE_FORMAT),
      ...baseCreateUpdateData,
    });
    void refetchUnavliability();
    setLoading(false);
    setUpdateDialogIsOpen(false);
    handleClose();
  };
  const save = async () => {
    if (isUpdatingUnavailability && hasRecurrentUnavailability) {
      setUpdateDialogIsOpen(true);
      return;
    }
    setLoading(true);
    if (isUpdatingUnavailability) {
      await updateUnavliability();
    } else {
      const res = await UnavailabilityService.createUnavailability({
        ...baseCreateUpdateData,
        repeating: getRepeatingData(),
      });
      addUavailabilities(res);
      setLoading(false);
      handleClose();
    }
  };

  const title = isUpdatingUnavailability
    ? t('calendar.unavailability_dialog.edit_title')
    : t('calendar.unavailability_dialog.title');
  return (
    <TitledDrawer onClose={handleClose} open={open} title={title}>
      <Grid
        container
        flexDirection="column"
        width={isSmallScreen ? window.innerWidth : '550px'}
        p={isSmallScreen ? '8px' : '16px'}
        pb="0px"
        overflow="hidden"
        height="100%">
        <Grid
          p="8px"
          container
          flexDirection="column"
          style={{ overflowY: 'scroll', maxHeight: 'calc(100% - 70px)' }}
          flexWrap="nowrap"
          height="100%"
          gap={2}>
          <Grid item>
            <TextField
              variant="outlined"
              sx={{ width: '231px' }}
              label={t('calendar.unavailability_dialog.description')}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          <Grid>
            <DatePicker
              open={startDateOpen}
              onOpen={() => setStartDateOpen(true)}
              onClose={() => setStartDateOpen(false)}
              label={t('calendar.unavailability_dialog.date')}
              format="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue!);
                if (newValue?.isSameOrAfter(endDate)) {
                  setEndDate(newValue);
                }
              }}
              disablePast
            />
          </Grid>
          <Grid
            item
            borderRadius="8px"
            border="1px solid #c4c4c4"
            p={1}
            pb={allDay ? 1 : 2}
            maxWidth={allDay ? '231px' : undefined}>
            <Grid item>
              <FormControlLabel
                value="start"
                control={<Switch checked={allDay} onChange={handleAllDayChange} color="primary" />}
                label={t('calendar.unavailability_dialog.all_day')}
              />
            </Grid>

            {!allDay && (
              <Grow in={!allDay} timeout={1200}>
                <Grid container gap={2}>
                  <MobileTimePicker
                    minTime={moment().set({ hour: 7, minute: 0, second: 0, millisecond: 0 })}
                    ampm={false}
                    open={startTimeOpen}
                    onOpen={() => setStartTimeOpen(true)}
                    onClose={() => setStartTimeOpen(false)}
                    label={t('calendar.unavailability_dialog.start_time')}
                    value={startTime}
                    onChange={(newValue) => {
                      setStartTime(newValue!);
                    }}
                  />
                  <MobileTimePicker
                    minTime={startTime}
                    ampm={false}
                    open={endTimeOpen}
                    onOpen={() => setEndTimeOpen(true)}
                    onClose={() => setEndTimeOpen(false)}
                    label={t('calendar.unavailability_dialog.end_time')}
                    value={endTime}
                    onChange={(newValue) => {
                      setEndTime(newValue!);
                    }}
                  />
                </Grid>
              </Grow>
            )}
          </Grid>
          {!isUpdatingUnavailability && (
            <Grid item borderRadius="8px" border="1px solid #c4c4c4" p={1} maxWidth={!repeating ? '231px' : undefined}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  checked={repeating}
                  label={t('calendar.unavailability_dialog.repeating')}
                  onChange={(e: any) => {
                    setRepeating(e.target.checked);
                    if (!e.target.checked) {
                      setEndDate(startDate.clone().startOf('day'));
                    }
                  }}
                />
              </FormGroup>

              {repeating && (
                <Grow in={repeating} timeout={500}>
                  <Grid>
                    <RepeatingDaysComponent />
                  </Grid>
                </Grow>
              )}
            </Grid>
          )}
          {privateOrdersPages && (
            <Grid container flexDirection="column" mt={4} gap={2} maxWidth={'400px'}>
              <Grid item mb={-2}>
                <Typography variant="h5">{t('calendar.unavailability_dialog.pagestitle')}</Typography>
              </Grid>

              <UnavailabilityOrderPagesMultiSelect
                orderPages={privateOrdersPages}
                unavailabilityDetails={unavailabilityDetails}
                setUnavailabilityDetails={setUnavailabilityDetails}
              />
            </Grid>
          )}
        </Grid>

        <Grid p={2} container justifyContent={'flex-end'}>
          <LoadingButton loading={loading} variant="contained" sx={{ textTransform: 'none' }} onClick={save}>
            {t('save')}
          </LoadingButton>
        </Grid>
      </Grid>
      <UnvailabilityUpdateDialogConfirmation
        dialogIsOpen={updateDialogIsOpen}
        setUpdateDialogIsOpen={setUpdateDialogIsOpen}
        recurrent_unavailability_id={unavailability?.recurrent_unavailability_id}
        onSubmit={(deletionMode: DeletionMode) => {
          if (deletionMode === DeletionMode.SINGLE) {
            void updateUnavliability();
          } else {
            void updateRepeatingUnavliability();
          }
        }}
      />
    </TitledDrawer>
  );
};

export default UnavailabilityDialog;
