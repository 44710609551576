import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { AccountData, AccountService, Language, OrderPageTypes } from '../api';
import { useAuth } from '../session/InternalAuthProvider';
import { useGetAccountAuthenticated } from '../session/swr/useGetAccountAuthenticated';
import { dynamicLabelSchema } from '../settings/sections/informationSection/dynamicLabelSchema';
import { ChooseWorkshopType } from './components/ChooseWorkshopType';
import { OrderPageDetails } from './components/OrderPageDetails';
import { getOrderPages } from '../common/AccountUtils';

type CreateExperiencePageValues = {
  order_page_type: OrderPageTypes;
  base_price: number;
  base_value: number;
  price_per_additional_value: number;
  label: { [key in Language]: string };
};
export const CreateExperiencePage = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { updateLocalAccount } = useGetAccountAuthenticated({});
  const { t } = useTranslation();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const [step, setStep] = useState(1);
  const languages = account.customer_facing_widgets_languages;

  const handleNext = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);

  const validationSchema = yup.object().shape({
    label: yup.object().shape(dynamicLabelSchema(t, languages, true)),
    base_price: yup.number().positive(t('editOrderPage.yup.hasToBePoitive')).required(t('editOrderPage.yup.required')),
    base_value: yup.number().positive(t('editOrderPage.yup.hasToBePoitive')).required(t('editOrderPage.yup.required')),
    price_per_additional_value: yup
      .number()
      .positive(t('editOrderPage.yup.hasToBePoitive'))
      .required(t('editOrderPage.yup.required')),
  });
  const initialValues: CreateExperiencePageValues = {
    order_page_type: OrderPageTypes.EVENT,
    base_price: undefined as unknown as number,
    base_value: undefined as unknown as number,
    price_per_additional_value: undefined as unknown as number,
    label: { en: '', he: '' },
  };
  const handleOrderPageTypeChange =
    (setFieldValue: (field: string, value: any) => void) => (orderPageType: OrderPageTypes) => {
      void setFieldValue('order_page_type', orderPageType);
      handleNext();
    };
  // @ts-ignore
  const onSubmit = async (values: CreateExperiencePageValues) => {
    const updatedAccount = (await AccountService.createOrderPage(values)) as unknown as AccountData;
    await updateLocalAccount(updatedAccount);
    const orderPages = getOrderPages(updatedAccount);
    const orderPageId = orderPages[orderPages.length - 1].id;
    posthog.capture('Added new workshop', { orderPageId });
    navigate(`/dashboard/settings/${orderPageId}#information`);
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, setFieldValue, isSubmitting }) => (
        <Form style={{ height: '100%', display: 'flex' }}>
          {step === 1 && <ChooseWorkshopType handleOrderPageTypeChange={handleOrderPageTypeChange(setFieldValue)} />}
          {step === 2 && (
            <OrderPageDetails handleBack={handleBack} baseValue={values.base_value} isSubmitting={isSubmitting} />
          )}
        </Form>
      )}
    </Formik>
  );
};
