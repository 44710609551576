import { Alert, FormControl, Grid, InputLabel, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Today } from '@mui/icons-material';
import moment from 'moment/moment';
import { isEmpty } from 'lodash';
import useSWR from 'swr';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useAuthInfo } from '@propelauth/react';
import { MonthViewSkeleton } from '../calendar/views/MonthViewSkeleton';
import DatesCarousel from '../calendar/DatesCarousel';
import { CalendarView, DATE_FORMAT } from '../calendar/CalendarWrapper';
import { EmployeeAvailabilityCell } from './EmployeeAvailabilityCell';
import { useUserAvailability } from './swr/useUserAvailability';
import { UserAvailabilityDialog } from '../calendar/dialogs/UserAvailabilityDialog/UserAvailabilityDialog';
import { AccountData, PropelUserModel, UserAvailabilityType, UserPreferencesService, UserService } from '../api';
import RotatingLoader from '../common/ui/RotatingLoader';
import { AvailabilityCircle } from './AvailabilityCircle';
import { useAuth } from '../session/InternalAuthProvider';
import { getUserFullName, getUserFullNameFromUserId } from '../calendar/components/UsersMultiSelect';

export const UserAvailabilityPage = () => {
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { user: loggedInUser, accessHelper } = useAuthInfo();
  const today = moment().startOf('month').format(DATE_FORMAT);
  const { t } = useTranslation();
  const [currentViewDate, setCurrentViewDate] = useState(today);
  const [selectedCellDate, setSelectedCellDate] = useState(moment().format(DATE_FORMAT));
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [users, setUsers] = useState<PropelUserModel[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(loggedInUser?.userId);
  const [isRefetching, setIsRefetching] = useState(false);
  const isViewingOtherUser = loggedInUser?.userId !== selectedUserId;
  const selectedPropelUser = users.find((user) => user.user_id === selectedUserId);
  const fullUserName = `${selectedPropelUser?.first_name} ${selectedPropelUser?.last_name}`;
  useEffect(() => {
    const fetchUsers = async () => {
      const users = await UserService.getUsers();
      if (setUsers) {
        setUsers(users);
      }
    };
    void fetchUsers();
  }, []);

  const previousNextCallbacks = {} as {
    previous: any;
    next: any;
  };
  const shouldAllowSelectUser = accessHelper?.isAtLeastRole(account.propel_account_id, 'Admin');
  const {
    isLoading: isLoadingUserAvailabilities,
    items: userAvailabilities,
    updateItem: updateUserAvailability,
    addItem: addUserAvailability,
    deleteItem: deleteUserAvailability,
    addItems: addUserAvailabilities,
    reFetch: reFetchUserAvailabilities,
  } = useUserAvailability({
    startDate: moment(currentViewDate).startOf('month').subtract(2, 'month').format(DATE_FORMAT),
    endDate: moment(currentViewDate).startOf('month').add(6, 'month').format(DATE_FORMAT),
    userId: selectedUserId,
  });

  const {
    data: userPreferences,
    isLoading: isLoadingUserPreferences,
    mutate: refetchUserPrefs,
  } = useSWR({ opId: 'getUserPreferences' }, () => UserPreferencesService.getUserPreferences(selectedUserId), {
    refreshInterval: 20000,
  });
  useEffect(() => {
    const refetchDataWithNewUser = async () => {
      setIsRefetching(true);
      await reFetchUserAvailabilities();
      await refetchUserPrefs();
      setIsRefetching(false);
    };

    void refetchDataWithNewUser();
  }, [selectedUserId]);

  const isLoading = isLoadingUserAvailabilities || isLoadingUserPreferences || isRefetching;
  const userWorkingDays = userPreferences?.working_days;
  const onCellClicked = (date: string) => {
    if (moment(date).isBefore(moment())) {
      return;
    }
    setSelectedCellDate(date);
    setEditDialogOpen(true);
  };
  const selectedDaysAvailability = userAvailabilities[selectedCellDate];

  return (
    <Grid container flexDirection="column">
      <Grid container alignItems="center" mt={isSmallScreen ? 2 : 1}>
        <DatesCarousel
          currentDate={currentViewDate}
          setCurrentDate={setCurrentViewDate}
          calendarView={CalendarView.MONTH}
          previousNextCallbacks={previousNextCallbacks}
        />
        <IconButton
          onClick={() => setCurrentViewDate(today)}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}>
          <Today />
        </IconButton>
        {shouldAllowSelectUser ? (
          isEmpty(users) ? (
            <Grid>
              <RotatingLoader width={'24px'} />
            </Grid>
          ) : (
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">{t('calendar.shift_dialog.employee')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                inputProps={{ minWidth: '200px' }}
                size="small"
                value={selectedUserId}
                label={t('calendar.shift_dialog.employee')}
                onChange={(e) => setSelectedUserId(e.target.value)}
                renderValue={(userId: string) => getUserFullNameFromUserId(users, userId)}>
                {users
                  ?.map((user: PropelUserModel) => ({ ...user, fullName: getUserFullName(user) }))
                  .sort((a: any, b: any) => a.fullName.localeCompare(b.fullName))
                  .map((user: any) => (
                    <MenuItem key={`MenuItem-${user.user_id}`} value={user.user_id}>
                      {user.fullName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )
        ) : null}
        <Grid container alignItems="center" gap={1} mt={1} mx={isSmallScreen ? 1 : undefined}>
          <AvailabilityCircle type={'working_days'} />
          <Typography>{t('userAvailability.standardSchedule')}</Typography>
          |
          <AvailabilityCircle type={UserAvailabilityType.AVAILABLE} />
          <Typography>{t('userAvailability.avliable')}</Typography>
          |
          <AvailabilityCircle type={UserAvailabilityType.BLOCKED} />
          <Typography>{t('userAvailability.blocked')}</Typography>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container minHeight={300}>
          <RotatingLoader />
        </Grid>
      ) : (
        <>
          {isViewingOtherUser && (
            <Grid my={1} maxWidth={'500px'}>
              <Alert severity="warning">{t('userAvailability.otherUser', { user: fullUserName })}</Alert>
            </Grid>
          )}
          <MonthViewSkeleton
            currentDate={currentViewDate}
            DayComponent={(props) => (
              <EmployeeAvailabilityCell
                userWorkingDays={userWorkingDays}
                userAvailabilities={userAvailabilities}
                dateToRender={moment(props.date).format('YYYY-MM-DD')}
                onCellClicked={onCellClicked}
                isViewingOtherUser={isViewingOtherUser}
              />
            )}
          />
        </>
      )}

      {editDialogOpen && (
        <UserAvailabilityDialog
          userWorkingDays={userWorkingDays}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          currentDate={selectedCellDate}
          userAvailability={isEmpty(selectedDaysAvailability) ? undefined : selectedDaysAvailability[0]}
          updateUserAvailability={updateUserAvailability}
          addUserAvailability={addUserAvailability}
          addUserAvailabilities={addUserAvailabilities}
          reFetchUserAvailabilities={reFetchUserAvailabilities}
          deleteUserAvailability={deleteUserAvailability}
          selectedUserId={selectedUserId!}
          fullUserName={fullUserName}
        />
      )}
    </Grid>
  );
};
