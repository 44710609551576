import PluginUtils from './PluginUtils';
import { DateAvailabilityOut, ExtraPaymentLineData, SlimEventData, Tip, VoucherOut } from '../api';
import { OrderPageIntent } from './OrderPage';

const initialEditOrderState: EditOrderState = {
  shouldAllowPriceOverride: false,
  calculatedPrice: 0,
  extraPaymentsLines: [],
};

const initialState: OrderState = {
  isInitialized: false,
  activeStep: 0,

  account: null,
  experience: null,
  orderPage: null,
  paymentUrl: null,

  details: {
    persons: 0,
    duration: 0,
    price: 0,
    rooms: 1,
    custom_details: {},
  },
  dateAndTimeDetails: {
    date: null,
    time: null,
  },
  personalDetails: {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  },
  order_id: '',
  voucher: undefined,
  voucherError: undefined,
  orderPageIntent: undefined,
  theme: undefined,
  has_payment_integration: false,
  isLoadingDates: true,
  availableDates: [],
  canSpam: true,
  specificOrderPage: {
    onlyCouples: false,
    onlyVouchers: false,
    noMinDate: false,
    troubleshoot: false,
  },
  returningCustomer: false,
  tip: undefined,
  discountId: undefined,
  returningCustomerFromOrderId: undefined,
};

export enum VoucherError {
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_USED = 'ALREADY_USED',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
}

export enum SpecificEventError {
  NO_MORE_TICKETS = 'NO_MORE_TICKETS',
}

export type CustomTheme = {
  textColor: string;
  borderColor: string;
  backgroundColor: string;
};

export type EditOrderState = {
  shouldAllowPriceOverride: boolean;
  calculatedPrice: number;
  extraPaymentsLines: ExtraPaymentLineData[];
};
export type OrderState = {
  isInitialized: boolean;
  activeStep: number;
  account: any;
  experience: any;
  orderPage: any;
  paymentUrl: any;
  details: {
    persons: number;
    duration: number;
    price: number;
    rooms: number;
    custom_details: Record<string, any>;
  };
  dateAndTimeDetails: {
    date: any;
    time: any;
  };
  personalDetails: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
  };
  order_id: string;
  voucher?: VoucherOut;
  voucherError?: VoucherError;
  orderPageIntent?: OrderPageIntent;
  theme?: CustomTheme;
  has_payment_integration: boolean;
  isLoadingDates: boolean;
  availableDates: DateAvailabilityOut[];
  canSpam?: boolean;
  specificOrderPage?: {
    onlyCouples?: boolean;
    onlyVouchers?: boolean;
    noMinDate?: boolean;
    troubleshoot?: boolean;
  };
  returningCustomer: boolean;
  specificEvent?: SlimEventData;
  specificEventError?: SpecificEventError;
  tip?: Tip;
  discountId?: string;
  returningCustomerFromOrderId?: string;
};
type StepPricingTier = {
  quantity: number;
  price_per_unit: number;
};

const calculateTieredPrice = (stepPricing: StepPricingTier[], quantity: number): number => {
  let totalPrice = 0;
  stepPricing.sort((a, b) => b.price_per_unit - a.price_per_unit);

  for (const tier of stepPricing) {
    if (quantity > 0) {
      const numItemsInTier = Math.min(quantity, tier.quantity);
      totalPrice += numItemsInTier * tier.price_per_unit;
      quantity -= numItemsInTier;
    } else {
      break;
    }
  }
  // if we still have more units, they get the last tier pricing
  if (quantity > 0) {
    totalPrice += quantity * stepPricing[stepPricing.length - 1].price_per_unit;
  }

  return totalPrice;
};

const calculateCustomPerUnitPrice = (
  {
    custom_per_unit_pricing,
    price_per_additional_value,
  }: { custom_per_unit_pricing: number[]; price_per_additional_value: number },
  quantity: number,
): number => {
  if (custom_per_unit_pricing.length >= quantity) {
    return custom_per_unit_pricing[quantity - 1];
  }
  return (
    custom_per_unit_pricing[custom_per_unit_pricing.length - 1] +
    (quantity - custom_per_unit_pricing.length) * price_per_additional_value
  );
};
const calcNumberPrice = (price_data: any, order_details: any) => {
  let price = price_data.base_price;
  let additional_values = order_details[price_data.data_type] - price_data.base_value;
  if (additional_values < 0) {
    additional_values = 0;
  }
  if (price_data.tiered_pricing.length) {
    price += calculateTieredPrice(price_data.tiered_pricing, additional_values);
  } else if (price_data.custom_per_unit_pricing.length) {
    // calculating based on the amount of persons
    price += calculateCustomPerUnitPrice(price_data, order_details[price_data.data_type]);
  } else {
    price += additional_values * price_data.price_per_additional_value;
  }

  return price;
};

const isMatchingBoolPrice = (price_data: any, order_details: any) => {
  if (price_data.data_type === 'custom') {
    return order_details.custom_details[price_data.name];
  }
  return false;
};

const isMatchingGtPrice = (price_data: any, order_details: any) => {
  if (price_data.data_type === 'custom') {
    const value = order_details.custom_details[price_data.name];
    if (value === null || value === undefined) {
      return false;
    }
    return value > price_data.value;
  }
  return false;
};

const getMatchingEnumPrice = (price_data: any, order_details: any) => {
  const matchingOption = price_data.options.find((option: any) => {
    if (price_data.data_type === 'custom') {
      return option.value === `${order_details.custom_details[price_data.name]}`;
    }

    return option.value === `${order_details[price_data.data_type]}`;
  });
  return matchingOption?.price || 0;
};

export const calcPriceObject = (price_data: any, order_details: any) => {
  let currentObj = price_data;
  let price;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (currentObj.type === 'enum') {
      currentObj = getMatchingEnumPrice(currentObj, order_details);
    } else if (currentObj.type === 'number') {
      price = calcNumberPrice(currentObj, order_details);
      return price;
    } else if (currentObj.type === 'bool') {
      if (isMatchingBoolPrice(currentObj, order_details)) {
        if (currentObj.price) {
          currentObj = currentObj.price;
        } else {
          return currentObj.price_value;
        }
      } else {
        return 0;
      }
    } else if (currentObj.type === 'gt') {
      if (isMatchingGtPrice(currentObj, order_details)) {
        if (currentObj.price) {
          currentObj = currentObj.price;
        } else {
          return currentObj.price_value;
        }
      } else {
        return 0;
      }
    }
  }
};

export const calcPrice = (
  price_data_list: any,
  order_details: any,
  date_details: any,
  plugins: any,
  shouldUseweekendPlugin?: boolean,
) => {
  let price = 0;
  for (const price_data of price_data_list) {
    price += calcPriceObject(price_data, order_details);
  }
  price = PluginUtils.handleWeekendAndIsraeliHolidaysPlugin(plugins, price, date_details.date, shouldUseweekendPlugin);
  return price;
};

const getDefaultCustomDetails = (orderPage: any) => {
  const result = {};
  for (const customDetail of orderPage.custom_details) {
    if (customDetail.default_value) {
      // @ts-ignore
      result[customDetail.name] = customDetail.default_value;
    }
  }
  return result;
};
// This is to render stations in display day
const getShowableCustomDetails = (
  orderPage: any,
): {
  name: string;
}[] => {
  const result: {
    name: string;
  }[] = [];
  for (const customDetail of orderPage.custom_details) {
    if (customDetail.should_show_in_details) {
      result.push({ name: customDetail.name });
    }
  }
  return result;
};

const OrderUtils = {
  initialOrderState: initialState,
  calculatePrice: calcPrice,
  getDefaultCustomDetails,
  getShowableCustomDetails,
  ORDER_PAGE_TTL: 10 * 60,
  initialEditOrderState,
};

export default OrderUtils;
