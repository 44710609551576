import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { KeyedMutator } from 'swr/_internal';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { DraggableItem } from './DraggableItems';
import { AccountData, AccountService, EmailTemplateType, Language } from '../../../api';

import { useDragDropContext } from './DragDropProvider';
import { useGetEmailTemplatesParts } from '../../swr/useGetEmailTemplatesParts';
import { clearDraggableItemsLocalStorage } from './DragDropLocalStorage';

type UpdateTemplateSubmitButtonProps = {
  selectedLanguage: Language;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
  templateType: EmailTemplateType;
  orderPageIdOverride?: string;
  isEditingTemplatesEnabled: boolean;
};
export const UpdateTemplateSubmitButton = ({
  selectedLanguage,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
  templateType,
  orderPageIdOverride,
  isEditingTemplatesEnabled,
}: UpdateTemplateSubmitButtonProps) => {
  const posthog = usePostHog();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { readOnly } = UseAuthPermissions();
  const { items, hasChanges } = useDragDropContext();
  const { refetchTemplates } = useGetEmailTemplatesParts();
  const isButtonDisabled = readOnly || isSubmitting || !hasChanges;

  const handleSubmit = async (values: DraggableItem[]) => {
    try {
      clearDraggableItemsLocalStorage();
      const blocks = compact(values.filter((item) => !item.isStatic).map((item) => item.toEmailBlockData?.()));
      const updatedAccount = await AccountService.updateEmailTemplate({
        email_blocks_data: blocks,
        language: selectedLanguage,
        template_type: templateType,
        order_page_id_override: orderPageIdOverride,
        disable_override: !isEditingTemplatesEnabled,
      });
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
      void refetchTemplates({});
      posthog.capture('Email content edited', { blocks, templateType, language: selectedLanguage });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <LoadingButton
      onClick={async () => {
        setIsSubmitting(true);
        await handleSubmit(items);
        setIsSubmitting(false);
      }}
      size="large"
      loading={isSubmitting}
      type="submit"
      variant="contained"
      color="primary"
      disabled={isButtonDisabled}>
      {t('emailTemplates.submit')}
    </LoadingButton>
  );
};
