import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditEmailTemplates } from './components/EditEmailTemplates';
import { EditEmailSettings } from './components/EditEmailSettings';
import { TabsPage } from '../common/components/TabsPage';

enum EmailTemplatesPageTab {
  TEMPLATES = 'templates',
  SETTINGS = 'templatessettings',
}

type EmailTemplatesPageProps = {
  orderPageIdOverride?: string;
};
export const EmailTemplatesPage = ({ orderPageIdOverride }: EmailTemplatesPageProps) => {
  const { t } = useTranslation();

  return (
    <TabsPage
      tabContent={{
        [EmailTemplatesPageTab.TEMPLATES]: <EditEmailTemplates orderPageIdOverride={orderPageIdOverride} />,
        [EmailTemplatesPageTab.SETTINGS]: <EditEmailSettings />,
      }}
    />
  );
};
