/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NoteData } from '../models/NoteData';
import type { NoteEditRequestData } from '../models/NoteEditRequestData';
import type { NoteRecurrentEditRequestData } from '../models/NoteRecurrentEditRequestData';
import type { NoteRequestData } from '../models/NoteRequestData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NoteService {
  /**
   * Create Note
   * @param requestBody
   * @returns NoteData Successful Response
   * @throws ApiError
   */
  public static createNote(requestBody: NoteRequestData): CancelablePromise<Array<NoteData>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/note',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Note
   * @param noteId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteNote(noteId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/note',
      query: {
        note_id: noteId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Edit Recurrent Note
   * @param requestBody
   * @returns NoteData Successful Response
   * @throws ApiError
   */
  public static editNoteRecurrent(requestBody: NoteRecurrentEditRequestData): CancelablePromise<Array<NoteData>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/note/recurrent',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Note
   * @param recurrentNoteId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteNoteRecurrent(recurrentNoteId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/note/recurrent',
      query: {
        recurrent_note_id: recurrentNoteId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Edit Note
   * @param requestBody
   * @returns NoteData Successful Response
   * @throws ApiError
   */
  public static editNote(requestBody: NoteEditRequestData): CancelablePromise<NoteData> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/note/edit',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Recurrent Note Count
   * @param recurrentNoteId
   * @returns number Successful Response
   * @throws ApiError
   */
  public static getRecurrentNoteCount(recurrentNoteId: string): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/note/recurrent-count',
      query: {
        recurrent_note_id: recurrentNoteId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
