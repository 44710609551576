import React, { useEffect } from 'react';
import '../marketplace/MarketPlaceMain.scss';
import { Box, useMediaQuery } from '@mui/material';

import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { theme } from '../common/GeneralUtils';
import { useAuth } from '../session/InternalAuthProvider';
import RotatingLoader from '../common/ui/RotatingLoader';

export const SECTION_BGCOLOR = 'rgba(57,154,31,0.38)';
export const homePageTheme = createTheme({
  customTheme: {},
  typography: {
    fontFamily: "'Heebo', sans-serif",
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#4abd00',
      light: '#79ecaf',
      dark: '#c92b1d',
    },
  },
});

const SCROLL_HEIGHT_TO_ACTIVE_TOPBAR = 100;

const Title = ({ children }: { children: string }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Typography align="center" style={{ fontSize: isSmallScreen ? '22px' : '40px', fontWeight: 700 }}>
      {children}
    </Typography>
  );
};
const SubTitle = ({ children }: { children: string }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Typography align="center" style={{ fontSize: isSmallScreen ? '11px' : '20px', fontWeight: 500 }}>
      {children}
    </Typography>
  );
};
const HomePage = () => {
  useEffect(() => {
    window.location.href = import.meta.env.VITE_REACT_APP_AUTH_URL;
  }, []);

  const {
    authState: { isInitialized, isAuthenticated },
  } = useAuth();

  if (!isAuthenticated && isInitialized) {
    window.location.href = '/dashboard/calendar';
    return null;
  }
  return (
    <Box
      sx={{
        minHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <RotatingLoader />
    </Box>
  );
};

export default HomePage;
