import React from 'react';
import { useAuthInfo } from '@propelauth/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { includes, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import UnavailabilityBox from './calendar-items/UnavailabilityBox';
import OrderBox from './calendar-items/OrderBox';
import HoursBorders from './views/components/HoursBorders';
import EventBox from './calendar-items/EventBox';
import { getCalendarCellLocations } from './calendar-items/CalendarCalculator';
import { CalendarItems } from './CalendarWrapper';
import { EnrichedEventData, OrderData } from '../api';
import { unifySlot } from './UnifiedShiftAndUserAvailability';
import { UnifiedShiftsUserAvailabilitiesBox } from './calendar-items/UnifiedShiftsUserAvailabilitiesBox';
import { useAuth } from '../session/InternalAuthProvider';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import { useCalendar } from './CalendarContext';
import DragDropProvider from './views/dragndrop/DragDropProvider';
import NoteBox from './calendar-items/NoteBox';

const CalendarDay = (props: {
  dateToRender: string;
  items: CalendarItems;
  shouldShowUnavailability: boolean;
  shouldShowOnlyCurrentUser: boolean;
  shouldShowShifts: boolean;
  shouldShowUserAvailabilities: boolean;
  openOrder: (event: any, order: OrderData) => void;
  openEvent: (event: EnrichedEventData) => void;
}) => {
  const employeesFeatureEnabled = useFeatureFlagEnabled('employees-feature-enabled');
  const { authState } = useAuth();
  // @ts-ignore
  const account = authState.account as AccountData;
  const { user } = useAuthInfo();
  const { readOnly } = UseAuthPermissions();
  const {
    // @ts-ignore
    selectedUsersIds,
  } = useCalendar();
  const {
    items,
    shouldShowUnavailability,
    shouldShowOnlyCurrentUser,
    shouldShowShifts,
    shouldShowUserAvailabilities,
    dateToRender,
    openOrder,
    openEvent,
  } = props;

  if (!items) {
    return <HoursBorders />;
  }
  const shouldHaveWinterTimeOffset = !moment.tz(moment(), account.timezone).isDST();
  const unavailabilitiesByDate = items.unavailabilities[dateToRender] || [];
  const notesByDate = items.notes[dateToRender] || [];
  const filteredNotes = notesByDate.filter((x) => !x.all_day);
  const filteredUnavailabilities = shouldShowUnavailability ? unavailabilitiesByDate?.filter((u) => !u.all_day) : [];
  const eventsByDate = items.events[dateToRender] || [];
  const filteredEvents = shouldShowOnlyCurrentUser
    ? eventsByDate?.filter((x) => x.employee_ids.includes(user?.userId || ''))
    : eventsByDate;
  const ordersByDate = items.orders[dateToRender] || [];
  const filteredOrders = shouldShowOnlyCurrentUser
    ? ordersByDate?.filter((x) => x.employee_ids.includes(user?.userId || ''))
    : ordersByDate;
  const filteredShifts = shouldShowShifts
    ? items.shifts[dateToRender]?.filter((x) =>
        isEmpty(selectedUsersIds) ? x : includes(selectedUsersIds, x.employee_id),
      ) || []
    : [];
  const userAvailabilities =
    shouldShowUserAvailabilities && (!account.should_hide_user_availability_from_employees || !readOnly)
      ? items.calendarUserAvailabilities[dateToRender]?.filter((x) =>
          isEmpty(selectedUsersIds) ? x : includes(selectedUsersIds, x.user_id),
        ) || []
      : [];

  const { unifiedSlot, filteredOrdersLeft, filteredEventsLeft } = unifySlot(
    filteredShifts,
    userAvailabilities,
    filteredOrders,
    filteredEvents,
    employeesFeatureEnabled,
  );

  const cssResult = getCalendarCellLocations(
    filteredOrdersLeft,
    filteredEventsLeft,
    filteredUnavailabilities,
    unifiedSlot,
    shouldHaveWinterTimeOffset,
    filteredNotes,
  );
  const shouldNotDragNDrop =
    readOnly || !employeesFeatureEnabled || (!shouldShowUserAvailabilities && !shouldShowShifts);
  return (
    <DragDropProvider orders={filteredOrders} shiftAndAvailabilities={unifiedSlot} events={filteredEvents}>
      <HoursBorders />
      {filteredOrdersLeft.map((order) => {
        const css = cssResult.find((x) => x.itemId === order.id);
        return (
          <OrderBox
            viewType="day"
            key={order.id}
            order={order}
            css={css}
            openOrder={openOrder}
            shouldNotDragNDrop={shouldNotDragNDrop}
          />
        );
      })}
      {filteredEventsLeft.map((event) => {
        const css = cssResult.find((x) => x.itemId === event.id);
        return (
          <EventBox
            viewType="day"
            key={event.id}
            event={event}
            css={css}
            openEvent={openEvent}
            shouldNotDragNDrop={shouldNotDragNDrop}
          />
        );
      })}
      {filteredUnavailabilities.map((unavailability) => {
        const css = cssResult.find((x) => x.itemId === unavailability.id);
        return <UnavailabilityBox viewType="day" key={unavailability.id} unavailability={unavailability} css={css} />;
      })}
      {filteredNotes.map((note) => {
        const css = cssResult.find((x) => x.itemId === note.id);
        return <NoteBox viewType="day" key={note.id} note={note} css={css} />;
      })}
      {unifiedSlot.map((shiftAndAvailability) => {
        const css = cssResult.find((x) => x.itemId === shiftAndAvailability.id);
        return (
          <UnifiedShiftsUserAvailabilitiesBox
            viewType="day"
            key={shiftAndAvailability.id}
            shiftsAndUserAvailability={shiftAndAvailability}
            css={css}
            date={dateToRender}
            openOrder={openOrder}
            openEvent={openEvent}
          />
        );
      })}
    </DragDropProvider>
  );
};

export default CalendarDay;
