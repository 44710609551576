import {
  Alert,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { OpenInNew } from '@mui/icons-material';
import { AccountService } from '../api';
import { useGetAccountAuthenticated } from '../session/swr/useGetAccountAuthenticated';
import { formatDate } from '../common/formatDate';

const STRIPE_AUTHORIZE_ENDPOINT_URL = 'https://connect.stripe.com/oauth/authorize';

export const StripePage = () => {
  const hasExecutedRef = useRef(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const queryParams = new URLSearchParams(window.location.search);
  const codeQueryParam = queryParams.get('code');
  const { account, updateLocalAccount } = useGetAccountAuthenticated({});

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(!!codeQueryParam);
  const responseType = 'code';
  const scope = 'read_write';
  const stripeTestModeEnabled = useFeatureFlagEnabled('stripe-test-mode-enabled');
  const clientId = stripeTestModeEnabled
    ? import.meta.env.VITE_REACT_APP_TEST_STRIPE_CLIENT_ID
    : import.meta.env.VITE_REACT_APP_STRIPE_CLIENT_ID;
  const redirectUri = `${window.location.origin}/dashboard/stripe`;
  const state = JSON.stringify({ isTestMode: stripeTestModeEnabled });
  const queryState = queryParams.get('state');
  let isTestMode = false;
  if (queryState) {
    isTestMode = JSON.parse(decodeURIComponent(queryState))?.isTestMode;
  }
  useEffect(() => {
    const createIntegration = async () => {
      if (codeQueryParam && !hasExecutedRef.current) {
        hasExecutedRef.current = true;

        const updatedAccount = await AccountService.createStripeIntegration(codeQueryParam, isTestMode);
        void updateLocalAccount(updatedAccount);
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
      }
    };
    void createIntegration();
  }, [codeQueryParam]);

  const authUrlParams = new URLSearchParams({
    response_type: responseType,
    client_id: clientId,
    scope,
    redirect_uri: redirectUri,
    state,
  }).toString();
  const authUrl = `${STRIPE_AUTHORIZE_ENDPOINT_URL}?${authUrlParams}`;
  const handleButtonClick = () => {
    setIsLoading(true);
    window.location.href = authUrl;
  };
  const accountHasIntegration = !!account.stripe_connect?.stripe_account_id;

  const stripeTaxIdLink = account.stripe_connect?.is_stripe_test_account
    ? `https://dashboard.stripe.com/test/tax-rates/${account.stripe_connect?.stripe_tax_id}`
    : `https://dashboard.stripe.com/tax-rates/${account.stripe_connect?.stripe_tax_id}`;

  return (
    <Grid container flexDirection="column" alignItems="center" justifyContent="center" gap={3} style={{ flex: 1 }}>
      <Card style={{ maxWidth: isSmallScreen ? '300px' : undefined }}>
        <CardContent>
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            gap={2}>
            <img src="/images/stripelogo.png" alt="whatsapp" width="200px" />
            <Typography variant="h6" fontWeight={700}>
              {accountHasIntegration ? t('stripe.connected') : t('stripe.connectToStripePayments')}
            </Typography>
          </Grid>
          {accountHasIntegration ? (
            <Table>
              <TableBody>
                <TableRow />
                <TableRow>
                  <TableCell>{t('stripe.accountName')}</TableCell>
                  <TableCell>{account.stripe_connect?.account_display_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('stripe.accountId')}</TableCell>
                  <TableCell>{account.stripe_connect?.stripe_account_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('stripe.createdAt')}</TableCell>
                  <TableCell>
                    {moment(account.stripe_connect?.created_at).format(formatDate(account).longDateFormat)}
                  </TableCell>
                </TableRow>
                {account.stripe_connect?.stripe_tax_id && (
                  <TableRow>
                    <TableCell>{t('stripe.taxRateId')}</TableCell>
                    <TableCell>
                      <Grid container alignItems="center">
                        <Typography>{account.stripe_connect?.stripe_tax_rate}%</Typography>
                        <IconButton onClick={() => window.open(stripeTaxIdLink, '_blank')}>
                          <OpenInNew style={{ width: '16px' }} />
                        </IconButton>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
                {account.stripe_connect?.is_stripe_test_account && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Alert severity="warning">
                        <Typography>{t('stripe.isTestMode')}</Typography>
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <Grid container alignItems="center" justifyContent="center" mt={4}>
              <LoadingButton
                loading={isLoading}
                sx={{
                  width: '200px',
                  height: '36px',
                  backgroundColor: '#635BFF',
                  '&:hover': {
                    backgroundColor: '#4B47CC',
                  },
                }}
                variant="contained"
                onClick={handleButtonClick}>
                {t('stripe.connectToStripe')}
              </LoadingButton>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
