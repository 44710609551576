import { AccountData, OrderPageData, OrderPageStatus, OrderPageTypes } from '../api';

// any is here as authstate is not yet properly typed
export const getOrderPage = (account: AccountData | any, orderPageId: string) => {
  if (!account || !orderPageId) {
    return undefined;
  }
  return account.experiences[0].order_pages.find((o: OrderPageData) => o.id === orderPageId);
};

export const getOrderPages = (account: AccountData | any, withArchived?: boolean) => {
  if (withArchived) {
    return account.experiences[0].order_pages;
  }
  return account.experiences[0].order_pages.filter((x: OrderPageData) => x.status !== OrderPageStatus.ARCHIVED);
};

export const accountHasEventOrderPages = (account: AccountData | any) => {
  return getOrderPages(account).find((x: OrderPageData) => x.order_page_type === OrderPageTypes.EVENT) !== undefined;
};

export const accountHasPrivateOrderPages = (account: AccountData | any) => {
  return getOrderPages(account).find((x: OrderPageData) => x.order_page_type === OrderPageTypes.PRIVATE) !== undefined;
};
