import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { getEmployeeNames } from '../../../CalendarUtils';
import { WorkshopCircle } from '../../../../common/ui/WorkshopCircle';
import { useAuth } from '../../../../session/InternalAuthProvider';
import { AccountData, EnrichedEventData, OrderData } from '../../../../api';
import { useLanguage } from '../../../../common/GeneralUtils';
import { useEventOrders } from '../../../swr/useEventOrders';
import { useCalendar } from '../../../CalendarContext';
import { getOrderPage } from '../../../../common/AccountUtils';

const calculateTotalCheckedInPersons = (orderList: OrderData[]) => {
  return isEmpty(orderList)
    ? 0
    : orderList.reduce((totalPersons, item) => {
        if (item.checkin && item.checkin.persons) {
          return totalPersons + item.checkin.persons;
        }
        return totalPersons;
      }, 0);
};
type EventBoxDialogInfoHeaderProps = {
  event: EnrichedEventData;
};
export const EventBoxDialogInfoHeader = ({ event }: EventBoxDialogInfoHeaderProps) => {
  const { isLoading, items: ordersList } = useEventOrders({ eventId: event.id });
  // @ts-ignore
  const { users } = useCalendar();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const language = useLanguage();
  const orderPage = getOrderPage(account, event.order_page_id);
  const { t } = useTranslation();
  // global setting doesnt seem to obe propely updated and refreshed on lang change
  moment.locale(language);
  const orderPageCustomData = orderPage.custom_details;
  const customUsages = event.custom_ticket_usages;

  return (
    <Grid container alignItems="flex-start" flexDirection="column" mx={1}>
      <Grid container mb={1} alignItems="center" gap={1}>
        <WorkshopCircle account={account} language={language} orderPageId={orderPage.id} />
        <Typography variant="h5">{orderPage.label[language]}</Typography>
      </Grid>
      <Typography variant="body1" color="#555555" fontWeight={700}>
        <Grid container>
          {moment(event.date).format('dddd, MMMM, DD')} ⋅ {moment.unix(event.time).utc().format('HH:mm')}-
          {moment
            .unix(event.time + event.duration)
            .utc()
            .format('HH:mm')}
        </Grid>
      </Typography>
      <Grid container>
        <Grid flexDirection="column" item>
          <Typography variant="body1" color="#555555">
            {`${t('calendar.event_popper.participants')}:`} {event.used_tickets}/{event.tickets}
          </Typography>
          {customUsages &&
            customUsages?.length > 0 &&
            customUsages.map((customTicketUsage) => (
              <Typography variant="body1" color="#555555">
                {`${orderPageCustomData.find((x: any) => x.name === customTicketUsage.name)?.label.en} ${t(
                  'calendar.event_popper.participants',
                )}: `}
                {customTicketUsage.used_tickets}/{customTicketUsage.tickets}
              </Typography>
            ))}

          <Typography variant="body1" color="#555555">
            {`${t('calendar.event_popper.numOfOrders')}:`} {isLoading ? 0 : ordersList.length}
          </Typography>
          <Typography variant="body1" color="#555555">
            {`${t('calendar.event_popper.numOfFreeTickets')}: `}
            {isLoading ? 0 : ordersList.filter((x) => x.price === 0).reduce((sum, item) => sum + item.persons, 0)}
          </Typography>

          {event.employee_ids.length > 0 && (
            <Grid item container gap={1}>
              <Typography variant="body1" color="#555555">
                {t('calendar.event_popper.employees')}
              </Typography>
              {getEmployeeNames(event.employee_ids, users).map((x) => (
                <Grid item key={x}>
                  <Typography variant="body1" color="#555555">
                    {x}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1" color="#555555">
              {t('calendar.event_popper.total_checkeding', {
                total: isLoading ? 0 : calculateTotalCheckedInPersons(ordersList),
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ marginInlineStart: '32px' }}>
          <Gauge
            width={100}
            height={100}
            value={event.used_tickets}
            startAngle={-90}
            endAngle={90}
            valueMax={event.tickets}
            text={({ value, valueMax }) => `${value} / ${valueMax}`}
            sx={() => ({
              [`& .${gaugeClasses.valueArc}`]: {
                fill: '#4ABD00',
              },
            })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
