import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DashboardService } from '../api';
import { DailyDashboardBox } from './DailyDashboardBox';
import { accountHasEventOrderPages, accountHasPrivateOrderPages } from '../common/AccountUtils';
import { useAuth } from '../session/InternalAuthProvider';

const dailyDashboardFetcher = async () => DashboardService.dailyDashboard();
export const DailyDashboard = () => {
  const { t } = useTranslation();

  const { authState } = useAuth();

  const { data: dailyDashboard, isLoading } = useSWR({ opId: 'getDailyDashboard' }, dailyDashboardFetcher);
  const hasEventPages = accountHasEventOrderPages(authState.account);
  const hasPrivateOrderPages = accountHasPrivateOrderPages(authState.account);
  const shouldShowTotalBox = hasEventPages && hasPrivateOrderPages;

  return (
    <Grid container gap={2}>
      <Grid container>
        <Typography variant="h5">{t('dashboard.title')}</Typography>
      </Grid>
      {hasEventPages && (
        <DailyDashboardBox
          isLoading={isLoading}
          value={dailyDashboard?.event_orders}
          personNumber={dailyDashboard?.total_persons_event}
          title={t('dashboard.ticketsForEvents')}
          units={t('dashboard.orders')}
        />
      )}
      {hasPrivateOrderPages && (
        <DailyDashboardBox
          isLoading={isLoading}
          value={dailyDashboard?.private_orders}
          personNumber={dailyDashboard?.total_persons_private}
          title={t('dashboard.ticketsForPrivateOrders')}
          units={t('dashboard.orders')}
        />
      )}
      {shouldShowTotalBox && (
        <DailyDashboardBox
          isLoading={isLoading}
          value={dailyDashboard?.total_persons}
          title={t('dashboard.totalPersons')}
        />
      )}
    </Grid>
  );
};
