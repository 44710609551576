import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationItem } from './MainSidebar';
import { MenuButton } from './MenuButton';

type NavigationMainButtonProps = {
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  navigationItem: NavigationItem;
  isSubMenuItem?: boolean;
};
export const NavigationItemButton = ({
  navigationItem,
  setIsSidebarOpen,
  isSubMenuItem,
}: NavigationMainButtonProps) => {
  const { key, name, icon, target, external, navigate } = navigationItem;
  const location = useLocation();
  const itemIsActive = location.pathname === navigate;

  return external ? (
    <a target="_blank" href={navigate} rel="noreferrer" key={key} style={{ textDecoration: 'none', color: 'inherit' }}>
      <MenuButton
        name={name}
        icon={icon}
        isSubMenuItem={isSubMenuItem}
        setIsSidebarOpen={setIsSidebarOpen}
        itemIsActive={itemIsActive}
      />
    </a>
  ) : (
    <Link to={navigate} target={target} key={key} style={{ textDecoration: 'none', cursor: 'pointer', width: '100%' }}>
      <MenuButton
        name={name}
        icon={icon}
        isSubMenuItem={isSubMenuItem}
        setIsSidebarOpen={setIsSidebarOpen}
        itemIsActive={itemIsActive}
      />
    </Link>
  );
};
