import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import './EditEventDialog.scss';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCalendar } from '../../CalendarContext';
import FinalDateDetails from './FinalDateDetails';
import TitledDrawer from '../../../common/ui/TitledDrawer';
import { getOrderPage, getOrderPages } from '../../../common/AccountUtils';
import { useAuth } from '../../../session/InternalAuthProvider';
import { useLanguage } from '../../../common/GeneralUtils';
import ToggleDays from '../../../common/ui/ToggleDays';
import {
  AccountData,
  CustomDetail,
  CustomTicketsData,
  EnrichedEventData,
  EventService,
  HolidayCalendar,
  OrderPageData,
  OrderPageTypes,
} from '../../../api';
import { DATE_FORMAT } from '../../CalendarWrapper';
import { UsersMultiSelect } from '../../components/UsersMultiSelect';

const getInitialEvent = () => {
  return {
    date: moment().format('YYYY-MM-DD'),
    time: 60 * 60 * 9,
    tickets: 50,
  };
};

const EditEventDialogContent = ({
  addEvents,
  updateEvent,
}: {
  addEvents?: (event: EnrichedEventData[]) => void;
  updateEvent?: (event: EnrichedEventData) => void;
}) => {
  const employeesFeatureEnabled = useFeatureFlagEnabled('employees-feature-enabled');
  const language = useLanguage();
  // @ts-ignore
  const { editEventDialogState, closeEditEventDialog, users } = useCalendar();
  const { t } = useTranslation();
  const { orderPageId } = editEventDialogState;
  let event = editEventDialogState.event as Partial<EnrichedEventData>;
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;

  const orderPage = getOrderPage(account, orderPageId);
  const eventOrderPages = getOrderPages(account).filter(
    (x: OrderPageData) => x.order_page_type === OrderPageTypes.EVENT,
  );
  let isEditEvent = true;
  if (!event) {
    event = getInitialEvent();
    isEditEvent = false;
  }
  const [dateValue, setDateValue] = useState(moment(event.date));
  const [timeValue, setTimeValue] = useState(event.time ? moment.unix(event.time).tz('utc') : moment());
  const [dateOpen, setDateOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);

  const [tickets, setTickets] = useState(event.tickets || 0);
  const [customTickets, setCustomTickets] = useState<CustomTicketsData[]>(event.custom_tickets || []);
  const [repeatingIsChecked, setRepeatingIsChecked] = useState(false);
  const [days, setDays] = useState([0, 1, 2, 3, 4]);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [endDate, setEndDate] = useState(moment(event.date).add(1, 'weeks'));

  const [loading, setLoading] = useState(false);

  const maxEndDate = moment().add(365, 'days');
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>(event.employee_ids || []);
  const [selectedOrderPage, setSelectedOrderPage] = useState(orderPage || eventOrderPages[0]);
  // currently half hacked only to support properly one setting (english for example).
  // if present, it is shown, if not, it is not.
  const englishCustomDetail = selectedOrderPage.custom_details.find((x: any) => x.name === 'english');

  const customDetails = event.custom_details;
  const [customDetailState, setCustomDetailState] = useState(!!customDetails?.[0] || false);
  const [hiddenFromCustomers, setHiddenFromCustomers] = useState(event.hidden_from_customers || false);
  const customPricedTicketsDetails = selectedOrderPage.objects.filter((x: any) => x.is_custom_price_ticket);
  const getRepeatingData = () => {
    if (!repeatingIsChecked) {
      return undefined;
    }
    return {
      enabled: repeatingIsChecked,
      days,
      end_date: endDate.format(DATE_FORMAT),
    };
  };

  const saveChanges = async () => {
    setLoading(true);
    const payload = {
      date: dateValue.format(DATE_FORMAT),
      time: timeValue.hours() * 3600 + timeValue.minutes() * 60,
      tickets,
      custom_tickets: customTickets,
      repeating: getRepeatingData(),
      event_id: '',
      account_id: '',
      experience_id: '',
      order_page_id: '',
      employee_ids: [] as string[],
      custom_details: [] as CustomDetail[],
      hidden_from_customers: hiddenFromCustomers,
    };
    if (isEditEvent) {
      payload.event_id = event.id!;
      payload.order_page_id = selectedOrderPage.id;
      payload.employee_ids = selectedUsersIds;
      // hack to make english checkbox for yaar
      payload.custom_details =
        englishCustomDetail && customDetailState ? [{ show_in_widget: true, label: englishCustomDetail.label }] : [];
      const editedEvent = await EventService.editEvent(payload);
      if (updateEvent) {
        updateEvent(editedEvent);
      }
    } else {
      // @ts-ignore
      payload.account_id = authState.account.id;
      // @ts-ignore
      payload.experience_id = authState.account.experiences[0].id;
      payload.order_page_id = selectedOrderPage.id;
      const createdEvents = await EventService.createEvent(payload);
      if (addEvents) {
        addEvents(createdEvents);
      }
    }

    setLoading(false);
    closeEditEventDialog();
  };

  const onSetCustomTicketValue = (newTicketsNumber: number, name: string) => {
    const oldCustomTickets = customTickets.find((x) => x.name === name);
    setCustomTickets([
      ...customTickets.filter((x) => x.name !== name),
      { ...oldCustomTickets, name, tickets: newTicketsNumber },
    ]);
  };
  const onSetCustomTicketHidden = (name: string) => {
    const oldCustomTickets = customTickets.find((x) => x.name === name);
    setCustomTickets([
      ...customTickets.filter((x) => x.name !== name),
      {
        ...oldCustomTickets,
        name,
        tickets: oldCustomTickets?.tickets || 0,
        hidden_from_customers: !oldCustomTickets?.hidden_from_customers,
      },
    ]);
  };

  return (
    <div className="edit-event-dialog-content">
      <Grid
        container
        gap={2}
        flexDirection="column"
        className="edit-event-dialog-content-internal"
        maxHeight={'90%'}
        flexWrap="nowrap">
        <FormControl>
          <Select
            value={selectedOrderPage.id}
            renderValue={() => (
              <Grid container alignItems="center">
                <div
                  style={{
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                    background: selectedOrderPage.color || '#6577b3',
                    marginInlineEnd: '12px',
                  }}
                />
                <Grid item>{selectedOrderPage.label[language]}</Grid>
              </Grid>
            )}
            onChange={(event) => {
              setSelectedOrderPage(eventOrderPages.find((x: any) => x.id === event.target.value));
            }}>
            {eventOrderPages.map((x: any) => (
              <MenuItem key={`eventOrderPages-${x.id}`} value={x.id}>
                {x.label[language]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row">
          <Box className="date-container">
            <DatePicker
              open={dateOpen}
              onOpen={() => setDateOpen(true)}
              onClose={() => setDateOpen(false)}
              label={t('calendar.edit_event.date')}
              value={dateValue}
              onChange={(newValue) => newValue && setDateValue(newValue)}
            />
          </Box>

          <MobileTimePicker
            minTime={moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 })}
            ampm={account.holiday_calendar === HolidayCalendar.US}
            label={t('calendar.edit_event.start_time')}
            open={timeOpen}
            onOpen={() => setTimeOpen(true)}
            onClose={() => setTimeOpen(false)}
            value={timeValue}
            minutesStep={15}
            onChange={(newValue) => {
              setTimeValue(newValue!);
            }}
          />
        </Stack>

        <FinalDateDetails date={dateValue} duration={selectedOrderPage.duration} time={timeValue} />
        <Grid>
          <TextField
            label={t('calendar.edit_event.tickets_number')}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={tickets}
            onChange={(e) => setTickets(+e.target.value)}
          />
        </Grid>

        {customPricedTicketsDetails.length > 0 && (
          <Grid container flexDirection="column" gap={2}>
            {customPricedTicketsDetails.map((customPricedTicketsDetail: any) => (
              <Grid container flexWrap="nowrap" alignItems="center" gap={1}>
                <Grid item xs={6}>
                  <TextField
                    label={customPricedTicketsDetail.label[language]}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={customTickets.find((x) => x.name === customPricedTicketsDetail.name)?.tickets || 0}
                    onChange={(e) => onSetCustomTicketValue(+e.target.value, customPricedTicketsDetail.name)}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          customTickets.find((x) => x.name === customPricedTicketsDetail.name)?.hidden_from_customers ||
                          false
                        }
                        onChange={() => onSetCustomTicketHidden(customPricedTicketsDetail.name)}
                      />
                    }
                    label={t('calendar.edit_event.hiddenFromCustomers')}
                  />
                </Grid>
              </Grid>
            ))}
            <Divider />
          </Grid>
        )}

        {isEditEvent && employeesFeatureEnabled && (
          <Grid container flexDirection="column" mt={2} gap={2}>
            <UsersMultiSelect
              users={users}
              selectedUsersIds={selectedUsersIds}
              setSelectedUsersIds={setSelectedUsersIds}
            />
            {englishCustomDetail && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={customDetailState} onChange={() => setCustomDetailState(!customDetailState)} />
                  }
                  label={englishCustomDetail.label[language]}
                />
              </Grid>
            )}
          </Grid>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={hiddenFromCustomers}
              onChange={() => setHiddenFromCustomers(!hiddenFromCustomers)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('calendar.edit_event.hiddenEventFromCustomers')}
        />
        {!isEditEvent && (
          <Box className="repeating-checkbox">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={repeatingIsChecked}
                label={t('calendar.edit_event.repeating')}
                onChange={(e) => {
                  // @ts-ignore
                  setRepeatingIsChecked(e.target.checked);
                }}
              />
            </FormGroup>
          </Box>
        )}
        {repeatingIsChecked && (
          <Grid item border={'1px solid #CCCCCC'} p={2} borderRadius="8px" mt={-2}>
            <Box className="days-container" alignItems="center">
              <Box className="label">{t('calendar.edit_event.in_days')}</Box>
              <Box className="toggle-days-container">
                <ToggleDays days={days} setDays={setDays} />
              </Box>
            </Box>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '14px' }}>
              <div>{t('calendar.edit_event.end_repeating')}</div>
              <Box className="repeating-date-picker">
                <DatePicker
                  open={endDateOpen}
                  onOpen={() => setEndDateOpen(true)}
                  onClose={() => setEndDateOpen(false)}
                  format="DD/MM/YYYY"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue || moment(event.date).add(1, 'weeks'))}
                  disablePast
                  maxDate={maxEndDate}
                />
              </Box>
            </Stack>
          </Grid>
        )}
      </Grid>
      <div className="footer">
        <LoadingButton
          type="submit"
          color="primary"
          loading={loading}
          variant="contained"
          onClick={saveChanges}
          sx={{ my: 2 }}>
          {t('save_changes')}
        </LoadingButton>
      </div>
    </div>
  );
};

const EditEventDialog = ({
  createVoucher,
  addEvents,
  updateEvent,
}: {
  createVoucher?: boolean;
  addEvents?: (event: EnrichedEventData[]) => void;
  updateEvent?: (event: EnrichedEventData) => void;
}) => {
  // @ts-ignore
  const { editEventDialogState, closeEditEventDialog } = useCalendar();
  const { t } = useTranslation();
  const { event } = editEventDialogState;

  let title = t('calendar.edit_event.create_event');
  if (event) {
    title = t('calendar.edit_event.edit_event');
  }

  return (
    <TitledDrawer title={title} open={editEventDialogState.isOpen} onClose={closeEditEventDialog}>
      <EditEventDialogContent addEvents={addEvents} updateEvent={updateEvent} />
    </TitledDrawer>
  );
};

export default EditEventDialog;
