import { CalendarService } from '../../api';
import { useItems } from './useItems';

const fetcher = async ({ startDate, endDate }: { startDate: string; endDate: string }) =>
  CalendarService.getCalendarUnavailabilities(startDate, endDate);

type useUnavailabilitiesProps = { startDate: string; endDate: string };

export const useUnavailabilities = ({ startDate, endDate }: useUnavailabilitiesProps) => {
  const { items, error, isLoading, deleteItem, addItems, updateItem, reFetch } = useItems({
    startDate,
    endDate,
    opId: 'getCalendarUnavailabilities',
    fetcher,
  });

  return {
    items: items || {},
    isLoading,
    isError: error,
    deleteItem,
    reFetch,
    addItems,
    updateItem,
  };
};
