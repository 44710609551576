import React from 'react';
import { OrdersReports } from './OrdersReports';
import { TabsPage } from '../common/components/TabsPage';
import { WorkshopsReports } from './WorkshopsReports';

enum ReportsPageTab {
  ORDERS = 'orders',
  WORKSHOPS = 'workshops',
}

export const ReportsPage = () => {
  const tabContent = {
    [ReportsPageTab.WORKSHOPS]: <WorkshopsReports />,
    [ReportsPageTab.ORDERS]: <OrdersReports />,
  };
  return <TabsPage tabContent={tabContent} />;
};
