import './OrderPage.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PersonalDetailsStep from './steps/PersonalDetailsStep';
import {
  AccountData,
  AccountService,
  OrderPageData,
  OrderPersonalDetailsRequestData,
  WaitingListService,
} from '../api';
import { useLanguage } from '../common/GeneralUtils';

import PageLoading from '../common/PageLoading';
import './WaitingList.scss';
import { OrderPageButton } from './OrderPageButton';
import { OrderPageTypography } from './OrderPageTypography';
import MonkeybookWidgetFooter from '../common/ui/MonkeybookWidgetFooter';
import { getOrderPage } from '../common/AccountUtils';

const WaitingList = () => {
  const theme = useTheme();
  console.log('suka');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef();
  const language = useLanguage();
  const { accountId, experienceId, orderPageId, date, persons } = useParams();
  const [account, setAccount] = useState<AccountData | undefined>(undefined);
  const [orderPage, setOrderPage] = useState<OrderPageData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  console.log(account);
  console.log(orderPage);
  useEffect(() => {
    void (async () => {
      const accountData = (await AccountService.getAccount(accountId)) as AccountData;
      const orderPage = getOrderPage(accountData, orderPageId || '');
      setAccount(accountData);
      setOrderPage(orderPage);
    })();
  }, [accountId, experienceId, orderPageId]);

  if (!account || !orderPage) {
    return <PageLoading />;
  }

  const sendRegisterData = async (values: OrderPersonalDetailsRequestData) => {
    return WaitingListService.registerToWaitingList({
      account_id: accountId!,
      experience_id: experienceId!,
      order_page_id: orderPageId!,
      language,
      persons: +persons!,
      dates: [date!],
      personal_details: { ...values },
    });
  };

  const submit = async () => {
    const data = formRef;
    // @ts-ignore
    await data?.current.submitForm();
    // @ts-ignore
    const { isValid } = data.current;
    if (isValid) {
      setLoading(true);
      // @ts-ignore
      await sendRegisterData(data.current.values);
      navigate('/waiting-list/completed');
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Monkeybook - {orderPage.label[language]} - {t('order_page.waiting_list.title')}
        </title>
      </Helmet>
      <Grid
        container
        display="flex"
        justifyContent="space-around"
        sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        style={{
          height: '100%',
          width: '100%',
        }}>
        <Grid
          container
          alignContent="center"
          flexDirection="column"
          style={{
            borderRadius: '12px',
            background: theme.customTheme.palette.background,
            height: '100%',
            width: '100%',
            maxWidth: '710px',
          }}>
          <Grid
            p={isSmallScreen ? 2 : 4}
            container
            flexDirection="column"
            style={{
              maxWidth: '650px',
              color: theme.customTheme.palette.text.primary,
              border: `2px solid ${theme.customTheme.palette.border}`,
              borderRadius: '12px',
            }}
            boxShadow={4}>
            <Grid item display="flex" justifyContent="center">
              <OrderPageTypography variant="h3" bold>
                {t('order_page.waiting_list.title')}
              </OrderPageTypography>
            </Grid>

            <PersonalDetailsStep formRef={formRef} orderPage={orderPage} />

            <Grid mt={4}>
              <OrderPageButton
                loadingButton
                variant="contained"
                onClick={submit}
                loading={loading}
                size="large"
                className="next-button">
                <span>{t('finish')}</span>
              </OrderPageButton>
            </Grid>
          </Grid>
          <Grid item mt={1}>
            <MonkeybookWidgetFooter languages={account.customer_facing_widgets_languages} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WaitingList;
