import './OrderCompletedPage.scss';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import TagManager from 'react-gtm-module';
import uuid from 'react-uuid';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import TTLLocalStorage from '../../common/TTLLocalStorage';
import { AccountData, AccountService, OrderData, OrderService, VoucherOut, VoucherService } from '../../api';
import { getOrderPageLabel } from '../../calendar/dialogs/UnavailabilityDialog/UnavailabilityOrderPagesMultiSelect';
import { deleteUtmDataFromLocalStorage, getUtmDataFromLocalStorage } from './utmLocalStorage';
import { getOrderPages } from '../../common/AccountUtils';

const GA4Pixel = ReactGA;
const FBPixel = ReactPixel;

const isSpeculoAccount = (account_id?: string) => {
  return account_id === '631f29922d95f4da6c05b077' || account_id === '631b15dbcae22107925686e3';
};

const isYaarAccount = (account_id?: string) => {
  return account_id === '64e246f85f3e1733728cb75f';
};

function reportTransactionGA4(transactionData: VoucherOut | OrderData, account: AccountData) {
  const orderPageName = getOrderPageLabel('en', getOrderPages(account), transactionData.order_page_id);
  GA4Pixel.event('purchase', {
    transaction_id: transactionData.id,
    category: 'Ecommerce',
    label: orderPageName,
    value: transactionData.price,
    currency: account.currency,
    items: [
      {
        item_name: `${orderPageName}-${transactionData.order_page_id}`,
        item_id: transactionData.id,
        price: transactionData.price,
        quantity: 1,
      },
    ],
  });
}

function reportTransactionFB(account?: AccountData, transactionData?: VoucherOut | OrderData) {
  if (!transactionData || !account || !account.marketing_integrations?.fb_pixel_id) {
    return;
  }
  const orderPageName = getOrderPageLabel('en', getOrderPages(account), transactionData.order_page_id);
  const options = {
    autoConfig: true, // set pixel's auto config
    debug: true, // enable logs
  };

  FBPixel.init(account.marketing_integrations.fb_pixel_id, undefined, options);
  FBPixel.track('Purchase', {
    value: transactionData.price,
    currency: account.currency,
    content_ids: [transactionData.id],
    content_type: 'product',
    contents: [
      {
        id: transactionData.id,
        quantity: 1,
        item_name: `${orderPageName}-${transactionData.order_page_id}`,
      },
    ],
  });
}

const OrderCompletedPage = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [tagManagerIsInitialized, setTagManagerIsInitialized] = useState(false);
  const [transactionEntity, setTransactionEntity] = useState<VoucherOut | OrderData | undefined>();
  const [account, setAccount] = useState<AccountData | undefined>();
  const orderPageId = searchParams.get('order_page_id') || undefined;
  const transactionType = searchParams.get('transaction_type') || undefined;
  const entityId = searchParams.get('entity_id') || undefined;
  const account_id = searchParams.get('account_id') || undefined;
  // there is no reason to keep transaction in memory
  TTLLocalStorage.removeByPrefix('order-page-state-');
  const postHog = usePostHog();
  useEffect(() => {
    if (account) {
      postHog.setPersonPropertiesForFlags({ accountId: account.id });
      postHog.identify(uuid(), {
        accountId: account.id,
        orderPageId,
        environment: import.meta.env.VITE_REACT_APP_ENVIRONMENT || 'local',
        context: 'order-page-completed',
      });
    }
  }, [account]);

  useEffect(() => {
    if (GA4Pixel.isInitialized && transactionEntity && account) {
      reportTransactionGA4(transactionEntity, account);
    }
  }, [GA4Pixel.isInitialized]);

  const utmParams = getUtmDataFromLocalStorage();
  const gtmTrackingEnabled = useFeatureFlagEnabled('direct-gtm-tracking-enabled');
  const shouldUseNewTracking = gtmTrackingEnabled && account?.marketing_integrations?.gtm_id;
  useEffect(() => {
    postHog.startSessionRecording({});
  }, []);
  useEffect(() => {
    if (transactionType && entityId) {
      const getEntityAndAccount = async () => {
        const fetchTransactionEntity = (async () => {
          if (transactionType === 'voucher') {
            return VoucherService.getVoucherById(entityId);
          }
          if (transactionType === 'order') {
            return OrderService.getOrder(entityId);
          }
          return undefined;
        })();

        const fetchAccountData = AccountService.getAccount(account_id);

        const [transactionEntity, accountData] = await Promise.all([fetchTransactionEntity, fetchAccountData]);

        setTransactionEntity(transactionEntity);
        setAccount(accountData);
        if (accountData?.marketing_integrations?.ga4_id) {
          GA4Pixel.initialize(accountData?.marketing_integrations?.ga4_id);
        }
        if (accountData?.marketing_integrations?.fb_pixel_id) {
          reportTransactionFB(accountData, transactionEntity);
        }
        postHog.capture('Transaction completed', { ...transactionEntity, account: accountData.name, utmParams });
      };

      void getEntityAndAccount();
    }
  }, [transactionType, entityId]);

  useEffect(() => {
    if (shouldUseNewTracking) {
      TagManager.initialize({
        // @ts-ignore
        gtmId: account?.marketing_integrations?.gtm_id,
      });
      setTagManagerIsInitialized(true);
    }
  }, [gtmTrackingEnabled, shouldUseNewTracking]);

  useEffect(() => {
    if ((isSpeculoAccount(account_id) || isYaarAccount(account_id)) && account && transactionEntity) {
      if (shouldUseNewTracking) {
        // should wait for initialization
        if (!tagManagerIsInitialized) {
          return;
        }
        const event = {
          event: 'purchase',
          ecommerce: {
            transaction_id: transactionEntity.id || uuid(),
            affiliation: 'Monkeybook.io',
            value: transactionEntity.price,
            tax: '',
            shipping: '',
            currency: account?.currency,
            coupon: '',
            items: [
              {
                item_name: getOrderPageLabel('en', getOrderPages(account), orderPageId!),
                item_id: orderPageId,
                price: transactionEntity.price,
                category: '',
                variant: '',
                quantity: 1,
              },
            ],
          },
          customer: {
            customer_id: uuid(),
            customer_first_name: transactionEntity.firstname,
            customer_last_name: transactionEntity.lastname,
            customer_email: transactionEntity.email,
            customer_phone: transactionEntity.phone,
            // customer_country: '',
            // customer_state: '',
            // customer_zipcode: '',
          },
          ...utmParams,
        };
        TagManager.dataLayer({
          dataLayer: event,
        });
        console.log('sent event', event);
        TTLLocalStorage.removeByPrefix('order-page-state-');
      } else {
        try {
          console.log('Going to send event to parent window');
          window.parent.postMessage(
            {
              name: 'transacationComplete',
              order_id: Math.floor(Math.random() * 100200300),
              revenue: transactionEntity.price, // Total transaction value (incl. tax and shipping)

              products: [
                {
                  name: getOrderPageLabel('en', getOrderPages(account), orderPageId!),
                  id: orderPageId,
                  price: transactionEntity.price,
                  category: '',
                  variant: '',
                  quantity: 1,
                },
              ],
              customer: {
                customer_first_name: transactionEntity.firstname,
                customer_last_name: transactionEntity.lastname,
                customer_email: transactionEntity.email,
                customer_phone: transactionEntity.phone,
                customer_country: 'US',
                customer_state: 'MA',
                customer_zipcode: '',
              },
            },
            '*',
          );
        } catch (e) {
          console.log('Error occured when trying to send event');
          console.log(e);
        }
        TTLLocalStorage.removeByPrefix('order-page-state-');
        deleteUtmDataFromLocalStorage();
      }
    }
  }, [tagManagerIsInitialized, account, transactionEntity]);
  TTLLocalStorage.removeByPrefix('order-page-state-');
  return (
    <>
      {
        // speculo accounts
        isSpeculoAccount(account_id) && !shouldUseNewTracking && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-148720460-1" />
            <script src="/scripts/speculo-gtag.js" />
            <script src="/scripts/speculo-facebook.js" />
            <noscript>
              <img
                alt="facebook"
                height="1"
                width="1"
                style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=935698493466004&ev=PageView&noscript=1"
              />
            </noscript>
          </>
        )
      }
      {
        // speculo accounts
        isYaarAccount(account_id) && !gtmTrackingEnabled && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-SZ6K3X7MKK" />
            <script src="/scripts/yaar-gtag.js" />
            <script src="/scripts/yaar-facebook.js" />
            <noscript>
              <img
                alt="facebook"
                height="1"
                width="1"
                style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=935698493466004&ev=PageView&noscript=1"
              />
            </noscript>
          </>
        )
      }

      <Helmet />
      <div className="order-completed-page" dir={i18n.dir()}>
        <div className="order-completed-page-box">
          <CheckCircle className="order-completed-icon" />
          <div className="title">{t('order_completed_page.title')}</div>
          <div className="sub-text">{t('order_completed_page.sub_text')}</div>
        </div>
      </div>
    </>
  );
};

export default OrderCompletedPage;
