import { DraggableItem } from './DraggableItems';

const ITEMS_LOCAL_STORAGE_KEY = 'webook-emailTemplateItems-';

// fixed component refreshing issye which was the reason for having this.
// checking for a couple of days if all good and then can delete.
export const saveDraggableItemsToLocalStorage = (items: DraggableItem[], key: string) => {
  // const path = window.location.pathname;
  // const emailBlockDataArray = items.map((item) => item.toEmailBlockData?.());
  // localStorage.setItem(`${ITEMS_LOCAL_STORAGE_KEY}-${key}-${path}`, JSON.stringify(emailBlockDataArray));
};

export const loadDraggableItemsFromLocalStorage = (key: string): DraggableItem[] => {
  return [];
  // const path = window.location.pathname;
  //
  // const data = localStorage.getItem(`${ITEMS_LOCAL_STORAGE_KEY}-${key}-${path}`);
  // if (!data) {
  //   return [];
  // }
  //
  // const emailBlockDataArray: EmailBlockData[] = JSON.parse(data);
  // return emailBlockDataArray.map(createDraggableItem);
};

export const clearDraggableItemsLocalStorage = () => {
  // for (let i = 0; i < localStorage.length; i++) {
  //   const key = localStorage.key(i);
  //   if (key?.startsWith(ITEMS_LOCAL_STORAGE_KEY)) {
  //     localStorage.removeItem(key);
  //   }
  // }
};
